"use client";

import * as React from "react";
import { Label, Pie, PieChart, Sector } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import { colors, getDeviceType } from "../../../../src/lib/utils";

const chartConfig = {
  equity: {
    label: "equity",
  },
};

export function PieChartComp({ data }) {
  const [chartData, setChartData] = React.useState([]);

  React.useLayoutEffect(() => {
    if (data) {
      const ret = data?.map((x, i) => ({
        equity: Number(x?.equity),
        name: x?.name,
        fill: colors[i],
      }));

      setChartData(ret);
    }
  }, [data]);

  return (
    <div className="w-[350px] h-[350px] ml-1">
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[350px]"
      >
        <PieChart>
          <Pie
            data={chartData}
            dataKey="equity"
            nameKey="name"
            innerRadius={getDeviceType() === "mobile" ? 85 : 100}
            strokeWidth={2}
            stroke={"#00000080"}
            animationBegin={0} // Start animation immediately
            isAnimationActive={true}
            animationDuration={800} // Duration of the animation
          />
        </PieChart>
      </ChartContainer>
    </div>
  );
}
