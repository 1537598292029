import file1 from "../src/local-pdf/dummy.pdf";
import file2 from "../src/local-pdf/sample.pdf";
import product1 from "../src/Assets/Icon/product1.png";
import product2 from "../src/Assets/Icon/product2.png";
import product3 from "../src/Assets/Icon/product3.png";

const data = [
  {
    id: "advisory",
    title: "Advisory",
    subData: [
      {
        subcatID: "1",
        subtitle: "Capital Raising",
        description: "Raise Debt Or Equity For Growth",
        innersubdata: [
          {
            innersubid: "1",
            innerdesc: [
              {
                bullet1:
                  "We provide innovative, objective and timely advice on all aspects of raising both debt and equity capital (IPO’s and private) underwriting, investor targeting, security structuring, and offering size and pricing.",
                bullet2:
                  "We also provide guidance on the credit ratings process.Products include senior debt,Asset backed securities,subordinated and mezzanine debt, convertible and equity-linked securities, and private equity.",

              },
            ],
          },
        ],
      },
      {
        subcatID: "2",
        subtitle: "Mergers & Acquisitions",
        description: "Know who to merge with or acquire",
        innersubdata: [
          {
            innersubid: "2",
            innerdesc: [
              {
                bullet1:
                  "We evaluate potential targets or merger partners as well as financial and strategic alternatives. We advise on strategy, timing, structure,financing, pricing, and we assist in negotiating and closing transactions. ",
                bullet2:
                  "We act as a dealer-manager in tender or exchange offers and advice on minority or majority sales, leveraged and management buy outs.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "3",
        subtitle: "Financial Restructuring",
        description: "Optimise your capital structure",
        innersubdata: [
          {
            innersubid: "3",
            innerdesc: [
              {
                bullet1:
                  "We advise clients in stressed or distressed situations on capital structure. We structure a wide variety of transactions, including tender and exchange offers, rescue financing or recapitalizations, “amend and extend” amendments and assets sales. ",
              },
            ],
          },
        ],
      },
      {
        subcatID: "4",
        subtitle: "Valuations",
        description: "Know who you are worth or what to pay",
        innersubdata: [
          {
            innersubid: "4",
            innerdesc: [
              {
                bullet1:
                  "We tailor our business valuations to our client’s specific needs and provide an overview of the company, industry and economy; we incorporate detailed exhibits that support our valuation and conclusion using multiple valuation methodologies .",
                bullet2:
                  "We represent independent directors and special committees with Independent objective advice, insight into shareholder concerns, clear and authoritative analysis.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "5",
        subtitle: "Leverage Buyouts",
        description: "More flexibility and investment options",
        innersubdata: [
          {
            innersubid: "5",
            innerdesc: [
              {
                bullet1:
                  "There are Participation products which closely track an underlying asset and carry same downside risk as the underlying. Yield enhancement products which pay a defined coupon and contain a risk buffer and capital protection, where you participate in the upside, but your downside is limited.",
                bullet2:
                  "These include credit linked notes, credit default swaps, interest swaps, currency swaps and other derivatives. ",
              },
            ],
          },
        ],
      },
    ],
    stats: [
      {
        maintitle: "A series of leading innovations….",
        statHeading: [
          {
            title: "First Listed Mortgaged Backed Bond",
            statnumber: [
              {
                common: "GHS",
                number: "5",
                size: "bn",
              },
            ],
            statdesc: "Equity capital transactions",
          },
          {
            title: "First Listed Rated Corporate Bond",
            statnumber: [
              {
                common: "GHS",
                number: "1.5",
                size: "bn",
              },
            ],
            statdesc: "Corporate bond programmes",
          },
          {
            title: "International Cross Border Listing",
            statnumber: [
              {
                common: "USD",
                number: "150",
                size: "mn",
              },
            ],
            statdesc: "Advisory mandates",
          },
          {
            title: "Cross Currency Asset Swap",
            statnumber: [
              {
                common: "USD",
                number: "200",
                size: "mn",
              },
            ],
            statdesc: "Deal structuring",
          },
          // {
          //   title: "Credit Default Swaps",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
        ],
      },
    ],
    overview: [
      {
        heading: "Your Vision Our Process",
        description:
          "We advise clients on a broad range of strategic and tactical issues, including mergers and Acquisitions, capital structure optimization, capital allocation, equity and debt market positioning, private and public issuance, and investor communications strategies",
        link: "Read more",
        path: file1,
        pdfName: "Advisory.pdf",
      },
    ],
    resources: [
      {
        tabs: [
          {
            title: "Resources",
            tab: [
              {
                subtitle: "Debt Capital Markets",
                id: "dcm",
                tabcontent: [
                  {
                    id: "1",
                    companyName: "Letshego Ghana Savings and Loans PLC",
                    companyData: [
                      {
                        id: "prospectus-1",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "AFB Prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-1",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-2",
                        companySubtitle: "Fact sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "2",
                    companyName: "Leasafric Ghana PLC",
                    companyData: [
                      {
                        id: "prospectus-2",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "AFB prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-3",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-4",
                        companySubtitle: "Fact Sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "ECM-1",
                id: "ecm",
                tabcontent: [
                  {
                    id: "3",
                    companyName: "Company-3",
                    companyData: [
                      {
                        id: "prospectus-3",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-5",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-6",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "4",
                    companyName: "Company-4",
                    companyData: [
                      {
                        id: "prospectus-4",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-7",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-8",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "Tab-1",
                id: "tab3",
                tabcontent: [
                  {
                    id: "5",
                    companyName: "Company-1",
                    companyData: [
                      {
                        id: "prospectus-5",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-9",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-10",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "6",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-6",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-11",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-12",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    solution: [
      {
        heading:
          "More trading options. More opportunities to grow.",
      },
    ],
    partnerclient: [
      {
        title:
          "We are proud to work with some of the largest international and local institutions",
        description:
          "A true  partnership is a two-way street - ideas and information flow openly and regularly,based on a  foundation of mutual trust and respect for one another's expertise - and our clients embrace this philosophy.",
        partnerdata: [
          {
            title: "International Institutional Clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "25",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Financial institutions",
            partnernumber: [
              {
                // common: "GHS",
                number: "63",
                // size: "bn",
              },
            ],
          },
          {
            title: "Retail and High Net worth clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "29",
                size: "k",
              },
            ],
          },
          {
            title: "Fund managers and Pensions",
            partnernumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Firms and Corporates",
            partnernumber: [
              {
                // common: "GHS",
                number: "30",
                // size: "bn",
              },
            ],
          },
        ],
      },
    ],
    product: [
      {
        title: "Product-Advisory",
        productGnii: [{}],
      },
    ],
  },
  {
    id: "markets",
    title: "Markets",
    subData: [
      {
        subcatID: "1",
        subtitle: "Bonds & Bills",
        description:
          "Buy & sell Bonds and T-Bills at auction or on the secondary market",
        innersubdata: [
          {
            innersubid: "1",
            innerdesc: [
              {
                bullet1:
                  "Consistent with our customer focused non-competitive ethic, our trading desk acts as an agency desk with respect to our customers’ business. An agency trading desk executes orders at the best price available for its clients and does not try to match trades, make markets, or engage in proprietary trading. ",
                bullet2:
                  "Our only agenda is to provide high quality, cost-effective executions; acting in effect as an extension of our client's business.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "2",
        subtitle: "Eurobonds",
        description: "We trade international bonds for on and offshore clients",
        innersubdata: [
          {
            innersubid: "2",
            innerdesc: [
              {
                bullet1:
                  "We deliver high quality executions of domestic and international trades that vary from small private client orders to complex trading strategies for institutional clients. ",
                bullet2:
                  "In addition to serving our affiliated registered representatives and investment advisors, our trading desk provides brokerage services to over 150 institutional clients, including some of the nation's premiere banks, money managers, international hedge funds and global banks.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "3",
        subtitle: "Local & Global Equities",
        description: "Buy & Sell local and global stocks through us",
        innersubdata: [
          {
            innersubid: "3",
            innerdesc: [
              {
                bullet1:
                  "We pride ourselves on our capabilities and commitment to providing first rate trading and execution services",
                bullet2:
                  "We are uniquely positioned by our ability to not only show clients strong, idea-generated trading capabilities across all our products, but also finance their transactions after settlement.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "4",
        subtitle: "Repos",
        description: "We provide short-term liquidity at competitive rates",
        innersubdata: [
          {
            innersubid: "4",
            innerdesc: [
              {
                bullet1:
                  "Our Matched Book Repo desk provides clients with access to our financing platform, offering leverage to both those who need it to finance their positions and those who are simply looking to diversify their current pool of liquidity. ",
                bullet2:
                  "We maintain every step of the customer relationship in-house and leverage long-standing connections to other repo desks on the street, which provides us with the ability to offer very competitive rates",
              },
            ],
          },
        ],
      },
      {
        subcatID: "5",
        subtitle: "Commodities & FX",
        description: "We source the best quotes and help you execute.",
        innersubdata: [
          {
            innersubid: "5",
            innerdesc: [
              {
                bullet1:
                  "We offer clients an innovative and wide-ranging product and service portfolio that includes structuring, trading and marketing of commodities, structured notes and FX.",
                bullet2:
                  "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
              },
            ],
          },
        ],
      },
    ],
    stats: [
      {
        maintitle: "Find out why we are the No.1 Broker",
        statHeading: [
          {
            title: "No. 1 ranked Broker by volume since 2019",
            statnumber: [
              {
                common: "GHS",
                number: "11",
                size: "bn",
              },
            ],
            statdesc: "Volume Traded",
          },
          {
            title: "Wide Client and Industry coverage",
            statnumber: [
              {
                common: "USD",
                number: "70",
                size: "mn",
              },
            ],
            statdesc: "Eurobonds Traded",
          },
          {
            title: "Client preferred settlement",
            statnumber: [
              {
                common: "GHS",
                number: "1.5",
                size: "mn",
              },
            ],
            statdesc: "Repos executed",
          },
          {
            title: "Repos to provide short term liquidity",
            statnumber: [
              {
                common: "USD",
                number: "22",
                size: "mn",
              },
            ],
            statdesc: "FX",
          },
          // {
          //   title: "Cross Asset Research platform",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title: "Alpha generating trade ideas",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
        ],
      },
    ],
    overview: [
      {
        heading: "Best Price and Execution",
        description:
          "We are on a journey to build one of the most unique independent broker-dealers in the industry. Whether you are actively trading or investing for the long term we give you everything you need to make smarter, more informed decisions.",
        link: "Read more",
        path: file2,
        pdfName: "Markets.pdf",
      },
    ],
    resources: [
      {
        tabs: [
          {
            title: "Resources",
            tab: [
              {
                subtitle: "DCM-2",
                id: "dcm",
                tabcontent: [
                  {
                    id: "7",
                    companyName: "Company-3",
                    companyData: [
                      {
                        id: "prospectus-7",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-13",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-1",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "8",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-8",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-14",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-15",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "ECM-2",
                id: "ecm",
                tabcontent: [
                  {
                    id: "9",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-9",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-15",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-16",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "10",
                    companyName: "Company-1",
                    companyData: [
                      {
                        id: "prospectus-10",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-17",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-18",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: "11",
                subtitle: "Tab-2",
                id: "tab3",
                tabcontent: [
                  {
                    companyName: "Company-1",
                    companyData: [
                      {
                        id: "prospectus-11",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-19",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-20",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "12",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-12",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-21",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-22",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    solution: [
      {
        heading: "More trading options. More opportunities to grow",
      },
    ],
    partnerclient: [
      {
        title:
          "We are proud to work with some of the largest international and local institutions2",
        description:
          "A true  partnership is a two-way street - ideas and information flow openly and regularly,based on a  foundation of mutual trust and respect for one another's expertise - and our clients embrace this philosophy.",
        partnerdata: [
          {
            title: "International Institutional Clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "25",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Financial institutions",
            partnernumber: [
              {
                // common: "GHS",
                number: "63",
                // size: "bn",
              },
            ],
          },
          {
            title: "Retail and High Net worth clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "29",
                size: "k",
              },
            ],
          },
          {
            title: "Fund managers and Pensions",
            partnernumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Firms and Corporates",
            partnernumber: [
              {
                // common: "GHS",
                number: "30",
                // size: "bn",
              },
            ],
          },
        ],
      },
    ],
    product: [
      {
        title: "Product-Markets",
        productGnii: [{}],
      },
    ],
  },
  {
    id: "investments",
    title: "Investments",
    subData: [
      {
        subcatID: "1",
        subtitle: "Financial Planning",
        description: "Lifelong dreams don't need to take a lifetime",
        innersubdata: [
          {
            innersubid: "1",
            innerdesc: [
              {
                bullet1:
                  "Create a plan that fits your goals. Tell us where you’d like to be, and we’ll do the rest. Your investment strategy can be customized with socially responsible investments and more.",
                bullet2:
                  "Built to help you reach your goals We'll build a diversified portfolio with investments that are handpicked by our team and that seek to provide optimal returns based on your goals and risk tolerance. Our advanced technology then monitors and automatically updates your portfolio to keep your goals on track.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "2",
        subtitle: "Wealth Management",
        description: "Grow your wealth, secure your future, achieve your goals",
        innersubdata: [
          {
            innersubid: "2",
            innerdesc: [
              {
                bullet1:
                  "Choose our fully managed or self-directed service for One-toone support: You get a dedicated Financial Consultant who works with you to determine your needs and supports you. Based on your goals, we will build a diversified portfolio for you from scratch",
                bullet2:
                  "You own all the securities in your depository account, providing full transparency into how your assets are managed actively managed or laddered portfolios with staggered maturity dates",
              },
            ],
          },
        ],
      },
      {
        subcatID: "3",
        subtitle: "Mutual Funds",
        description: "we seek to deliver attractive, risk adjusted returns ",
        innersubdata: [
          {
            innersubid: "3",
            innerdesc: [
              {
                bullet1:
                  "We are committed to providing clients with access to the firm’s network of resources. We pride ourselves on our time-tested investment approach and our team, all of whom are entrenched in the day-to-day oversight of our client’s investments.",
                bullet2:
                  "We believe that senior-level, hands-on oversight, combined with our singular focus on seeking to develop and implement distinctive, Active strategies, provides us with the capability to offer diversified and tailored investment solutions and be prepared for tomorrow’s evolving investment landscape.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "4",
        subtitle: "Pensions",
        description: "Life begins at retirement",
        innersubdata: [
          {
            innersubid: "4",
            innerdesc: [
              {
                bullet1:
                  "We let clients understand how their portfolios are being managed. We identify, review and analyse portfolios to ascertain the investment opportunities and risks within the portfolio. We can help clients benchmark, stress test and monitor the daily performance on a marked to market basis. We work with the Board of Trustees, investment committees and finance departments to provide a bird’s eye view of all investments and the return and risk attribution of the portfolio.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "5",
        subtitle: "Portfolio Advisory",
        description: "Create value by allocating resources with confidence",
        innersubdata: [
          {
            innersubid: "5",
            innerdesc: [
              {
                bullet1:
                  "We let clients understand how their portfolios are being managed. We identify, review and analyse portfolios to ascertain the investment opportunities and risks within the portfolio. We can help clients benchmark, stress test and monitor the daily performance on a marked to market basis. We work with the Board of Trustees, investment committees and finance departments to provide a bird’s eye view of all investments and the return and risk attribution of the portfolio.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "6",
        subtitle: "Liquidity Solutions",
        description: "Supporting your most pressing liquidity challenges",
        innersubdata: [
          {
            innersubid: "6",
            innerdesc: [
              {
                bullet1:
                  "We are committed to leveraging our insights, scale and experience. Our decision-making and strategy planning tools, diverse funding options, and the trading strategies and platforms of our execution solutions help you access the capital markets.",
                bullet2:
                  "Our Funding and Collateral Solutions team will help you address collateral requirements across a wide spectrum of transactions,",
              },
            ],
          },
        ],
      },
    ],
    stats: [
      {
        maintitle: "we seek to deliver attractive, risk-adjusted returns",
        statHeading: [
          {
            title: "Best Fixed income mutual fund over 3 years",
            statnumber: [
              {
                common: "GHS",
                number: "1",
                size: "bn",
              },
            ],
            statdesc: "Asset Under Management",
          },
          {
            title: "Best Balanced mutual fund over 3 years",
            statnumber: [
              {
                // common: "GHS",
                number: "5",
                // size: "bn",
              },
            ],
            statdesc: "Mutual Funds",
          },
          {
            title: "Actively trade all our portfolios",
            statnumber: [
              {
                common: "GHS",
                number: "90",
                size: "mn",
              },
            ],
            statdesc: "Mutual Funds",
          },
          {
            title: "All portfolios are marked to market and Benchmarked",
            statnumber: [
              {
                // common: "GHS",
                number: "50",
                // size: "mn",
              },
            ],
            statdesc: "Portfolio Mandates",
          },
          // {
          //   title: "Zero to low fees on all mutual fund portfolios",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title: "No loads on all Fixed income funds",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
        ],
      },
    ],
    overview: [
      {
        heading: "Creating Futures",
        description:
          "We are building a unique, forward-looking investment firm underpinned by insightful research, innovation and creativity. We pair an array of diverse investment offerings with a high degree of transparency, which we believe provides our clients with the confidence and trust to thoughtfully place our strategies within the context of their portfolios.",
        link: "Read more",
        path: file1,
        pdfName: "Investments.pdf",
      },
    ],
    resources: [
      {
        tabs: [
          {
            title: "Resources",
            tab: [
              {
                subtitle: "FUNDS",
                id: "dcm",
                tabcontent: [
                  {
                    id: "13",
                    companyName: "Fixed Income Alpha Plus",
                    companyData: [
                      {
                        id: "prospectus-13",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Alpha Plus Properties",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-23",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "FIAP Annual Report 2019",
                          },
                          {
                            link: file2,
                            pdfName: "FIAP Annual Report 2020",
                          },
                          {
                            link: file1,
                            pdfName: "Fixed Income Alpha Plus Fund PLC Annual Report",
                          },
                        ],
                      },
                      {
                        id: "annual-24",
                        companySubtitle: "Fact Sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "January 2020",
                          },
                          {
                            link: file2,
                            pdfName: "February 2020",
                          },
                          {
                            link: file1,
                            pdfName: "March 2020",
                          },
                          {
                            link: file1,
                            pdfName: "April 2020",
                          },
                          {
                            link: file1,
                            pdfName: "May 2020",
                          },
                          {
                            link: file1,
                            pdfName: "June 2020",
                          },
                          {
                            link: file1,
                            pdfName: "November 2020",
                          },
                          {
                            link: file1,
                            pdfName: "January 2021",
                          },
                          {
                            link: file2,
                            pdfName: "February 2021",
                          },
                          {
                            link: file2,
                            pdfName: "April 2021",
                          },
                          {
                            link: file2,
                            pdfName: "May 2021",
                          },
                          {
                            link: file2,
                            pdfName: "June 2021",
                          },
                          {
                            link: file2,
                            pdfName: "December 2020",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "14",
                    companyName: "Christian Community Mutual Fund",
                    companyData: [
                      {
                        id: "prospectus-14",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "CCMF Prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "CCMF sub form",
                          },
                          {
                            link: file1,
                            pdfName: "2012 CCMF Prospectus",
                          },
                        ],
                      },
                      {
                        id: "annual-25",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "2011 CCMF Prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "2013 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "2014 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "2015 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "2016 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "2017 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "2018 CCCMF Prospectus",
                          },
                          {
                            link: file1,
                            pdfName: "CCCMF Annual Report 2019",
                          },
                          {
                            link: file1,
                            pdfName: "CCCMF 2020 Annual Report",
                          },
                        ],
                      },
                      {
                        id: "annual-26",
                        companySubtitle: "Fact Sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "CCMF Account Numbers",
                          },
                          {
                            link: file2,
                            pdfName: "August 2020",
                          },
                          {
                            link: file1,
                            pdfName: "December 2021",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "Achieve",
                id: "ecm",
                tabcontent: [
                  {
                    id: "15",
                    companyName: "Plus Income Fund",
                    companyData: [
                      {
                        id: "prospectus-15",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Plus Income Fund Prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-27",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Plus Income Fund 2019 Annual Report",
                          },
                          {
                            link: file2,
                            pdfName: "Plus Income Fund 2020 Annual Report",
                          },
                          {
                            link: file1,
                            pdfName: "Plus Income Fund Annual Report 2021",
                          },
                        ],
                      },
                      {
                        id: "annual-28",
                        companySubtitle: "Fact Sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "June 2020",
                          },
                          {
                            link: file2,
                            pdfName: "July 2020",
                          },
                          {
                            link: file1,
                            pdfName: "August 2020",
                          },
                          {
                            link: file1,
                            pdfName: "September 2020",
                          },
                          {
                            link: file1,
                            pdfName: "October 2020",
                          },
                          {
                            link: file1,
                            pdfName: "December 2020",
                          },
                          {
                            link: file1,
                            pdfName: "January 2021",
                          },
                          {
                            link: file1,
                            pdfName: "February 2021",
                          },
                          {
                            link: file1,
                            pdfName: "March 2021",
                          },
                          {
                            link: file1,
                            pdfName: "April 2021",
                          },
                          {
                            link: file1,
                            pdfName: "May 2021",
                          },
                          {
                            link: file1,
                            pdfName: "July 2021",
                          },
                          {
                            link: file1,
                            pdfName: "August 2021",
                          },
                          {
                            link: file1,
                            pdfName: "September 2021",
                          },
                          {
                            link: file1,
                            pdfName: "October 2021",
                          },
                          {
                            link: file1,
                            pdfName: "November 2021",
                          },
                          {
                            link: file1,
                            pdfName: "December 2021",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "16",
                    companyName: "Plus Balanced Fund",
                    companyData: [
                      {
                        id: "prospectus-16",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Plus Balanced Fund Prospectus",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-29",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Plus Balanced Fund 2019 Annual Report",
                          },
                          {
                            link: file2,
                            pdfName: "Plus Balanced Fund 2020 Annual Report",
                          },
                          {
                            link: file1,
                            pdfName: "Plus Balanced Fund Annual Report 2021",
                          },
                        ],
                      },
                      {
                        id: "annual-30",
                        companySubtitle: "Fact Sheet",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "June 2020",
                          },
                          {
                            link: file2,
                            pdfName: "July 2020",
                          },
                          {
                            link: file1,
                            pdfName: "August 2020",
                          },
                          {
                            link: file2,
                            pdfName: "September 2020",
                          },
                          {
                            link: file2,
                            pdfName: "October 2020",
                          },
                          {
                            link: file2,
                            pdfName: "December 2020",
                          },
                          {
                            link: file2,
                            pdfName: "January 2021",
                          },
                          {
                            link: file2,
                            pdfName: "February 2021",
                          },
                          {
                            link: file2,
                            pdfName: "March 2020",
                          },
                          {
                            link: file2,
                            pdfName: "April 2021",
                          },
                          {
                            link: file2,
                            pdfName: "May 2021",
                          },
                          {
                            link: file2,
                            pdfName: "July 2021",
                          },
                          {
                            link: file2,
                            pdfName: "August 2021",
                          },
                          {
                            link: file2,
                            pdfName: "October 2021",
                          },
                          {
                            link: file2,
                            pdfName: "November 2021",
                          },
                          {
                            link: file2,
                            pdfName: "December 2021",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    solution: [
      {
        heading:
          "We tailor investment strategies to meet our clients' needs across a broad array of assets.",
      },
    ],
    partnerclient: [
      {
        title:
          "We are proud to work with some of the largest international and local institutions",
        description:
          "A true  partnership is a two-way street - ideas and information flow openly and regularly,based on a  foundation of mutual trust and respect for one another's expertise - and our clients embrace this philosophy.",
        partnerdata: [
          {
            title: "International Institutional Clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "25",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Financial institutions",
            partnernumber: [
              {
                // common: "GHS",
                number: "63",
                // size: "bn",
              },
            ],
          },
          {
            title: "Retail and High Net worth clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "29",
                size: "k",
              },
            ],
          },
          {
            title: "Fund managers and Pensions",
            partnernumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Firms and Corporates",
            partnernumber: [
              {
                // common: "GHS",
                number: "30",
                // size: "bn",
              },
            ],
          },
        ],
      },
    ],
    product: [
      {
        title: "Product-Investments",
        productGnii: [{}],
      },
    ],
  },
  {
    id: "analytics",
    title: "Analytics",
    subData: [
      {
        subcatID: "1",
        subtitle: "Market Insights",
        description: "Stay ahead of market changes",
        innersubdata: [
          {
            innersubid: "1",
            innerdesc: [
              {
                bullet1:
                  "We are committed to leveraging our insights, scale and experience. Our decision-making and strategy planning tools, diverse funding options, and the trading strategies and platforms of our execution solutions help you access the capital markets.",
                bullet2:
                  "Our Funding and Collateral Solutions team will help you address collateral requirements across a wide spectrum of transactions,",
              },
            ],
          },
        ],
      },
      {
        subcatID: "2",
        subtitle: "Economic Views",
        description: "Know which moments matter",
        innersubdata: [
          {
            innersubid: "2",
            innerdesc: [
              {
                bullet1:
                  "We deliver high quality executions of domestic and international trades that vary from small private client orders to complex trading strategies for institutional clients. ",
                bullet2:
                  "In addition to serving other investment advisors, our trading desk provides brokerage services to over 150 institutional clients, including some of the nation’s premier banks, money managers, international hedge funds and global banks.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "3",
        subtitle: "Trading Data",
        description: "Understanding the Market Direction",
        innersubdata: [
          {
            innersubid: "3",
            innerdesc: [
              {
                bullet1:
                  "You can now unlock the insight you need on how the market is shifting and where new opportunities are emerging in real-time to deliver Strong portfolio performance based on real data.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "4",
        subtitle: "Cross Asset Research",
        description: "Fundamental analysis synthesized across all asset clases",
        innersubdata: [
          {
            innersubid: "4",
            innerdesc: [
              {
                bullet1:
                  "We advise clients in stressed or distressed situations on capital structure. We structure a wide variety of transactions, including tender and exchange offers, rescue financing or recapitalizations, “amend and extend” amendments and assets sales."
              },
            ],
          },
        ],
      },
    ],
    stats: [
      {
        maintitle: "See data like you have never seen before",
        statHeading: [
          {
            title: "Comprehensive Bond and Equity Indices",
            statnumber: [
              {
                // common: "GHS",
                number: "15",
                // size: "mn",
              },
            ],
            statdesc: "Market Indices",
          },
          {
            title: "Daily market research & commentary",
            statnumber: [
              {
                // common: "GHS",
                number: "7",
                // size: "bn",
              },
            ],
            statdesc: "Bond indices",
          },
          {
            title: "Flash notes on market activity intraday",
            statnumber: [
              {
                // common: "GHS",
                number: "8",
                // size: "mn",
              },
            ],
            statdesc: "Other indices",
          },
          {
            title: "Macro strategies and portfolio themes.",
            statnumber: [
              {
                // common: "GHS",
                number: "7",
                // size: "bn",
              },
            ],
            statdesc: "Research & Analytics",
          },
          // {
          //   title: "Actionable ideas on a daily and weekly basis.",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title: "In-depth views of single stocks and earnings!",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title:
          //     "Weekly webinars and interviews with our research team providing timely updates.",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
        ],
      },
    ],
    overview: [
      {
        heading: "Results not Reports",
        description:
          "Discerning analysis starts with a depth of research and insight. We help you make informed decisions with incredible charts and insights from our reputed Datamind and Cross asset research desk that provides strategic synthesis of key information on asset classes to provide an integrated view",
        link: "Read more",
        path: file1,
        pdfName: "Analytics.pdf",
      },
    ],
    resources: [
      {
        tabs: [
          {
            title: "Resources",
            tab: [
              {
                subtitle: "DCM-4",
                id: "dcm",
                tabcontent: [
                  {
                    id: "17",
                    companyName: "Company-8",
                    companyData: [
                      {
                        id: "prospectus-17",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-31",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-32",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "18",
                    companyName: "Company-9",
                    companyData: [
                      {
                        id: "prospectus-18",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-33",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-34",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "ECM-4",
                id: "ecm",
                tabcontent: [
                  {
                    id: "19",
                    companyName: "Company-1",
                    companyData: [
                      {
                        id: "prospectus-19",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-34",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-35",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "20",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-20",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-36",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-37",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "Tab-4",
                id: "tab3",
                tabcontent: [
                  {
                    id: "21",
                    companyName: "Company-8",
                    companyData: [
                      {
                        id: "prospectus-21",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-38",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-39",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "22",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-22",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-40",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-41",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    solution: [
      {
        heading:
          "Tailoring innovative financing strategies for challenging market conditions.",
      },
    ],
    partnerclient: [
      {
        title:
          "We are proud to work with some of the largest international and local institutions4",
        description:
          "A true  partnership is a two-way street - ideas and information flow openly and regularly,based on a  foundation of mutual trust and respect for one another's expertise - and our clients embrace this philosophy.",
        partnerdata: [
          {
            title: "International Institutional Clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "25",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Financial institutions",
            partnernumber: [
              {
                // common: "GHS",
                number: "63",
                // size: "bn",
              },
            ],
          },
          {
            title: "Retail and High Net worth clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "29",
                size: "k",
              },
            ],
          },
          {
            title: "Fund managers and Pensions",
            partnernumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Firms and Corporates",
            partnernumber: [
              {
                // common: "GHS",
                number: "30",
                // size: "bn",
              },
            ],
          },
        ],
      },
    ],
    product: [
      {
        title: "Product-Analytics",
        productGnii: [{}],
      },
    ],
  },
  {
    id: "gnii",
    title: "GNII",
    subData: [
      {
        subcatID: "1",
        subtitle: "GNII Wealth",
        description:
          "Tailored, tech-forward wealth management",
        innersubdata: [
          {
            innersubid: "1",
            innerdesc: [
              {
                bullet1:
                  "Our advisors are here to help you with all parts of your life, not just investing. First, we take the time to learn about your immediate and distant financial priorities and establish an open line of communication. We then formulate plans and put them into action to safeguard and improve your most prized possessions.",
                bullet2:
                  "The wealth module provides Portfolio Position & Exposures, Security Valuation & cash flow projections and Asset allocation Analysis. Monitor client accounts to ensure that client portfolios conform to stated objectives and risk profiles.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "2",
        subtitle: "GNII Analytics",
        description: "Drive growth with the right data, insights and technology",
        innersubdata: [
          {
            innersubid: "2",
            innerdesc: [
              {
                bullet1:
                  "Security and portfolio level analytics with 50+ analytic measures calculated on each security and portfolio, Concentrations across sectors, issuers, countries, ratings, etc. Time series/trend analysis. View economic and market data in easy-to-understand charts and reports. ",
                bullet2:
                  "Common data with risk analytics in one place Establish visibility into a common set of analytics to keep you informed consistently and manage multiple aspects of your business from a single platform.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "3",
        subtitle: "GNII Risk",
        description: "Understand portfolio risk and performance",
        innersubdata: [
          {
            innersubid: "3",
            innerdesc: [
              {
                bullet1:
                  "The GNII Risk module can be used independently of the rest of the GNII platform. Using our highly developed risk analytics and efficient processing power, it allows businesses and individuals to examine their entire portfolio, comprehend their risks and exposures, and take swift, accurate action to gain insights.",
                bullet2:
                  "The risk module provides, Portfolio Position & Exposures, Portfolio Risk & Scenario Analysis, Security Valuation & cash flow projections, Performance & Attribution and Asset allocation Analysis.",
              },
            ],
          },
        ],
      },
      {
        subcatID: "4",
        subtitle: "GNII Enterprise",
        description: "GNII connects you to the investment world",
        innersubdata: [
          {
            innersubid: "4",
            innerdesc: [
              {
                bullet1:
                  "This is an operating system for investment professionals and institutions that powers every aspect of the investment process. This end-to-end portfolio management software combines sophisticated performance and risk analytics with comprehensive portfolio management tools, trading, operations, compliance and accounting tools on a single platform.",
                bullet2:
                  "With a single, standardized data set, Gnii brings clarity, efficiency, scalability, and cost savings to the entire investment lifecycle. Gnii native risk analysis makes Gnii is a truly end-to-end platform.",
              },
            ],
          },
        ],
      },
      // {
      //   subcatID: "5",
      //   subtitle: "Research",
      //   description: "We source the best quotes and help you execute",
      //   innersubdata: [
      //     {
      //       innersubid: "5",
      //       innerdesc: [
      //         {
      //           bullet1:
      //             "We offer clients an innovative and wide-ranging product and service portfolio that includes structuring, trading and marketing of commodities, structured notes and FX.",
      //           bullet2:
      //             "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   subcatID: "6",
      //   subtitle: "Get news & alerts",
      //   description: "We source the best quotes and help you execute",
      //   innersubdata: [
      //     {
      //       innersubid: "6",
      //       innerdesc: [
      //         {
      //           bullet1:
      //             "We offer clients an innovative and wide-ranging product and service portfolio that includes structuring, trading and marketing of commodities, structured notes and FX.",
      //           bullet2:
      //             "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
    stats: [
      {
        maintitle: "Track, Trade, analyze, and be in full control of all of your investments - all in one place!",
        statHeading: [
          {
            title: "Comprehensive Bond and Equity Indices",
            statnumber: [
              {
                // common: "GHS",
                number: "200",
                size: "+",
              },
            ],
            statdesc: "Fixed income & equity securities ",
          },
          {
            title: "Portfolio Risk & Scenario Analysis",
            statnumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
            statdesc: "Risk, performance & benchmark measures",
          },
          {
            title: "Mark to Market & cash flow projections",
            statnumber: [
              {
                // common: "GHS",
                number: "20",
                // size: "mn",
              },
            ],
            statdesc: "Portfolio attributes and exposure limits",
          },
          {
            title: "Performance, Attribution and Asset allocation Analysis.",
            statnumber: [
              {
                // common: "GHS",
                number: "17",
                // size: "bn",
              },
            ],
            statdesc: "Indices and benchmarks",
          },
          // {
          //   title: "Actionable ideas on a daily and weekly basis.",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title: "In-depth views of single stocks and earnings!",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
          // {
          //   title:
          //     "Weekly webinars and interviews with our research team providing timely updates.",
          //   statnumber: [
          //     {
          //       common: "GHS",
          //       number: "1.4",
          //       size: "bn",
          //     },
          //   ],
          //   statdesc: "Structured Transactions",
          // },
        ],
      },
    ],
    overview: [
      {
        heading: "Imagine More",
        description:
          "The wealth management of the future is powered by GNII™, an industry-leading platform. built using the portfolio and risk analysis software that sophisticated institutions and international asset managers utilize. We are redefining the wealth management industry as well as the way institutional and individual investors manage their investment portfolios.",
        link: "Read more",
        path: file1,
        pdfName: "Gnni.pdf",
      },
    ],
    resources: [
      {
        tabs: [
          {
            title: "Resources",
            tab: [
              {
                subtitle: "DCM-5",
                id: "dcm",
                tabcontent: [
                  {
                    id: "23",
                    companyName: "Company-1",
                    companyData: [
                      {
                        id: "prospectus-23",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-41",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-42",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "24",
                    companyName: "Company-2",
                    companyData: [
                      {
                        id: "prospectus-24",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-43",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-44",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                subtitle: "ECM-5",
                id: "ecm",
                tabcontent: [
                  {
                    id: "25",
                    companyName: "Company-8",
                    companyData: [
                      {
                        id: "prospectus-25",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-45",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-46",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "26",
                    companyName: "Company-9",
                    companyData: [
                      {
                        id: "prospectus-26",
                        companySubtitle: "Prospectus",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-47",
                        companySubtitle: "Annual Reports",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                      {
                        id: "annual-48",
                        companySubtitle: "Annual Reports All",
                        downloadpdf: [
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 1",
                          },
                          {
                            link: file2,
                            pdfName: "Prospectus PDF 2",
                          },
                          {
                            link: file1,
                            pdfName: "Prospectus PDF 3",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    solution: [
      {
        heading:
          "a deep understanding of analytics and our clients allows us to apply technology to solve real personal and business needs",
      },
    ],
    partnerclient: [
      {
        title:
          "We are proud to work with some of the largest international and local institutions",
        description:
          "A true  partnership is a two-way street - ideas and information flow openly and regularly,based on a  foundation of mutual trust and respect for one another's expertise - and our clients embrace this philosophy.",
        partnerdata: [
          {
            title: "International Institutional Clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "25",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Financial institutions",
            partnernumber: [
              {
                // common: "GHS",
                number: "63",
                // size: "bn",
              },
            ],
          },
          {
            title: "Retail and High Net worth clients",
            partnernumber: [
              {
                // common: "GHS",
                number: "29",
                size: "k",
              },
            ],
          },
          {
            title: "Fund managers and Pensions",
            partnernumber: [
              {
                // common: "GHS",
                number: "40",
                // size: "bn",
              },
            ],
          },
          {
            title: "Local Firms and Corporates",
            partnernumber: [
              {
                // common: "GHS",
                number: "30",
                // size: "bn",
              },
            ],
          },
        ],
      },
    ],
    product: [
      {
        title: "",
        productGnii: [
          {
            title: "Product1-GNII",
            img: product1,
            innerdata: [
              {
                bullet1:
                  "We provide innovative, objective and timely advice on all aspects of raising both debt and equity capital (IPO’s and private) underwriting, investor targeting, security structuring, and offering size and pricing.",
                bullet2:
                  "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
                link: "info@blackstargroup.com",
              },
            ],
            id: 1,
          },
          {
            title: "Product2-GNII",
            img: product2,
            innerdata: [
              {
                bullet1:
                  "We provide innovative, objective and timely advice on all aspects of raising both debt and equity capital (IPO’s and private) underwriting, investor targeting, security structuring, and offering size and pricing.",
                bullet2:
                  "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
                link: "info@blackstargroup.com",
              },
            ],
            id: 2,
          },
          {
            title: "Product3-GNII",
            img: product3,
            id: 3,
            innerdata: [
              {
                bullet1:
                  "We provide innovative, objective and timely advice on all aspects of raising both debt and equity capital (IPO’s and private) underwriting, investor targeting, security structuring, and offering size and pricing.",
                bullet2:
                  "Our currency management service provides foreign exchange execution and currency hedging solutions to help minimize risk, reduce costs and increase efficiency.",
                link: "info@blackstargroup.com",
              },
            ],
          },
        ],
      },
    ],
  },
];
export default data;
