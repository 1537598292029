import React, { useState, useRef, useEffect } from "react";
import "../ReadMore_Download/readmore.scss";
import pdf from "../../Assets/Icon/pdf.svg";
import { useLocation } from "react-router-dom";
import validator from "validator";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { saveAs } from "file-saver";
import { COUNTRY_LIST } from "../../country";

const Readmore = () => {
  // console.log("array", COUNTRY_LIST);
  const form = useRef();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [countryOptions, setCountry] = useState([]);
  const [message, setMessage] = useState(false);
  const saveFile = () => {
    saveAs(`${location.state.path}`, `${location.state.pdfName}`);
  };
  const [data, setData] = useState({
    full_name: "",
    email: "",
    country: "",
  });
  const [error, setError] = useState({
    full_name: "",
    email: "",
    country: "",
  });
  const handlecountry = (val, name) => {
    setData((prevState) => ({ ...prevState, [name]: val }));
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  const checkdata = (data) => {
    let localError = {};

    if (!validator.isEmail(data?.email)) {
      localError.email = "Please enter a valid email address";
    }
    if (validator.isEmpty(data?.full_name)) {
      localError.full_name = "Please enter full name";
    }
    if (validator.isEmpty(data?.country?.toString())) {
      localError.country = "Please select country";
    }
    return { localError, isValid: Object.keys(localError).length <= 0 };
  };
  const onFormSubmit = (e) => {
    e.preventDefault();
    const { isValid, localError } = checkdata(data);
    if (isValid) {
      setError({});
      setLoader(true);
      saveFile();
      emailjs
        .sendForm(
          "service_8asu0xk",
          "template_37n9zvt",
          form.current,
          "HDrMLazpnyUdz0-qZ"
        )
        .then(
          (result) => {
            toast.success("Email Send Succesfully");
            setData({
              full_name: "",
              email: "",
              country: "",
            });
            setLoader(false);
            setMessage(true);
          },
          (error) => {
            toast.error("Something went wrong. Please try again");
          }
        );
    } else {
      setError(localError);
    }
  };
  useEffect(() => {
    let countryArr = [];
    COUNTRY_LIST?.map((country) => {
      countryArr.push({
        label: country?.name,
        value: country?.name,
      });
    });
    setCountry(countryArr);
  }, []);
  return (
    <React.Fragment>
      <div className="section-readmore">
        <div className="readmorecontent">
          <div className="readmore-text">
            <div className="readmore-desc">
              <p>Fill the form and download the book to read more about us.</p>
            </div>
            <div className="readmore-img">
              <img
                src={pdf}
                alt="Signup"
                className="signupimg mx-auto d-block"
              ></img>
            </div>
          </div>
          {message ? (
            <div className="download-pdf">
              <div className="downloadmessage">
                <h3>
                  if the book is not automatically downloaded,
                  <a
                    href={location.state.path}
                    rel="noopener noreferrer"
                    target="_blank"
                    download={location.state.pdfName}
                  >
                    CLICK HERE
                  </a>
                  to download now.
                </h3>
              </div>
            </div>
          ) : (
            <form className="readmore-form" ref={form} onSubmit={onFormSubmit}>
              <div className="readmore-head">
                <h2>Sign Up</h2>
              </div>
              <div className="fullname-wrapper">
                <input
                  type="text"
                  className="fullname"
                  placeholder="Full name"
                  name="full_name"
                  onChange={handlechange}
                  value={data?.full_name}
                ></input>
                <p className="error-text">{error?.full_name}</p>
              </div>
              <div className="email-wrapper">
                <input
                  type="email"
                  className="emailadd"
                  placeholder="Enter email address"
                  name="email"
                  onChange={handlechange}
                  value={data?.email}
                ></input>
                <p className="error-text">{error?.email}</p>
              </div>
              <div className="country-wrapper">
                <Select
                  options={countryOptions}
                  name="country"
                  onChange={(e) => handlecountry(e, "country")}
                  value={data.country}
                  placeholder="Please select country"
                />
                <p className="error-text">{error?.country}</p>
              </div>
              <div className="submit-form">
                <button className="submit-btn" disabled={loader}>
                  Submit
                </button>
                {loader && (
                  <div class="spinner-border spinner m-5" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  );
};

export default Readmore;
