import Header from "../../../../../src/components/Header/Header";
import { getDeviceType } from "../../../../../src/lib/utils";
import { numberWithCommasAndDecimal } from "../../../../../src/Utils/helper";
import React, { useEffect, useState } from "react";
import { MAIN_TITLE_LOGO } from "../../../../../src/components/IFunds/IFunds";
import { MarketStatusPopover } from "./MainHeader";

const StickyHeader = ({
  isScrolled,
  isIndex,
  equitySum,
  livePrice,
  lineChartData2,
  fundDetails,
  portSum,
  menu,
  liveChanges,
  navMenu,
  page,
  setpage,
  KEYS,
  prevlivePrice,
}) => {
  const [priceChangeClass, setpriceChangeClass] = useState("text-white");
  const lastUnitPrice =
    lineChartData2[lineChartData2?.length - 1]?.unitPrice ||
    fundDetails?.unitPrice;
  const formattedPrice =
    numberWithCommasAndDecimal(livePrice || lastUnitPrice, 4) || "--";

  useEffect(() => {
    if (portSum?.portfolioName !== "Global Multi Sector Index") {
      setpriceChangeClass("text-white");
    } else {
      livePrice > prevlivePrice && isIndex
        ? setpriceChangeClass("text-green-500 animate-pulse")
        : setpriceChangeClass("text-red-500 animate-pulse");

      setTimeout(() => {
        setpriceChangeClass("text-white");
      }, 1000);
    }
  }, [liveChanges?.change]);

  return (
    <div
      id="smallElement"
      className={`bg-black pt-4 px-0 fixed -top-5 left-0 w-full transition-opacity duration-300 ${
        isScrolled ? "opacity-100 fadeDown" : "opacity-0 pointer-events-none"
      }`}
      style={{ zIndex: 20 }}
    >
      <Header />
      <div className="flex flex-col md:px-1 2xl:px-[4.7rem]">
        <div className="flex items-center justify-between mt-2.5 px-4">
          <EquityInfo
            KEYS={KEYS}
            isIndex={isIndex}
            equitySum={equitySum}
            formattedPrice={formattedPrice}
            priceChangeClass={priceChangeClass}
            liveChanges={liveChanges}
            fundDetails={fundDetails}
            portSum={portSum}
            menu={menu}
          />
          {getDeviceType() !== "mobile" && (
            <MarketStatusPopover isIndex={isIndex} />
          )}
        </div>
        <div className="mt-2" />
        <Header2
          data={navMenu}
          page={page}
          setpage={setpage}
          isScrolled={isScrolled}
        />
      </div>
    </div>
  );
};

const EquityInfo = ({
  isIndex,
  equitySum,
  formattedPrice,
  priceChangeClass,
  liveChanges,
  fundDetails,
  KEYS,
  menu,
}) => (
  <div className="flex items-center w-full">
    {equitySum?.logoPath ? (
      <img
        src={
          isIndex
            ? `https://blackstar-prod-public-milan.s3.eu-south-1.amazonaws.com/${equitySum?.logoPath}`
            : MAIN_TITLE_LOGO[menu]?.logo ||
              `https://blackstar-prod-public-milan.s3.eu-south-1.amazonaws.com/${equitySum?.logoPath}`
        }
        className="w-7 h-7 mr-3 rounded-sm"
      />
    ) : (
      <div className="w-7 h-7 mr-3  rounded-lg bg-zinc-900 animate-pulse" />
    )}
    <div
      className={`flex items-center${
        getDeviceType() === "mobile" && "justify-between w-full"
      }`}
    >
      <text className="text-lg font-bold mr-2">{KEYS[menu - 1]}</text>

      <div className="-mr-7 flex items-center">
        <text
          className={`text-base font-bold mr-1 ${priceChangeClass} transition-colors`}
        >
          {formattedPrice}
        </text>
        <text className="text-xs text-gray-400 mr-3 mt-0.5">GHS</text>

        {liveChanges?.change === 0 ? (
          <text className={`min-w-[35px] text-xs text-zinc-600 mr-3`}>
            No Change
          </text>
        ) : liveChanges?.change ? (
          <PriceChangeInfo
            isIndex={isIndex}
            liveChanges={liveChanges}
            fundDetails={fundDetails}
          />
        ) : (
          <div className="animate-pulse rounded-lg w-[150px] h-[25px] bg-zinc-900 mb-1 fade-in-5 fade-out-5" />
        )}
      </div>
    </div>
  </div>
);

const PriceChangeInfo = ({ isIndex, liveChanges, fundDetails }) => (
  <>
    {liveChanges?.percentageChange !== 0 && (
      <text
        className={`text-base ${
          liveChanges?.change < 0 ? "text-red-400" : "text-green-400"
        } mr-0.5`}
      >
        {liveChanges?.change < 0 ? "-" : "+"}
      </text>
    )}
    {liveChanges?.percentageChange !== 0 && (
      <text
        className={`min-w-[35px] text-base ${
          liveChanges?.change < 0 ? "text-red-400" : "text-green-400"
        } mr-2`}
      >
        {isIndex
          ? Math.abs(Number(liveChanges?.change ?? 0)).toFixed(4)
          : numberWithCommasAndDecimal(
              Math.abs(fundDetails?.unitPriceOneDayChange)
            )}
      </text>
    )}
    {liveChanges?.percentageChange !== 0 && (
      <text
        className={`min-w-[35px] text-base ${
          liveChanges?.change < 0 ? "text-red-400" : "text-green-400"
        } mr-5`}
      >
        {isIndex
          ? Math.abs(Number(liveChanges?.percentageChange ?? 0)).toFixed(2) ?? 0
          : numberWithCommasAndDecimal(
              Math.abs(fundDetails?.unitPriceOneDayChangePercentage)
            )}
        %
      </text>
    )}
    {liveChanges?.percentageChange === 0 && (
      <text className={`min-w-[35px] text-xs text-zinc-600 mr-3`}>
        No Change
      </text>
    )}
  </>
);

export default StickyHeader;

export const Header2 = ({ page, data, setpage, isScrolled }) => {
  const handleTabClick = (name) => {
    setpage(name);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`wrapper-sub-menu ${
        isScrolled ? "px-4" : "px-0"
      } no-scrollbar overflow-x-scroll`}
    >
      <div className="main-sub-menu-tab">
        {data?.map((x, i) => (
          <div
            className={`sub-tab ${page === x.name && "active"} ${
              getDeviceType() === "mobile" && "h-[50px]"
            }`}
            style={{
              borderBottom: page == x.name ? "0.5px solid #f4bd12" : "",
            }}
            onClick={() => {
              handleTabClick(x.name);
            }}
          >
            <p className="my-1">{x?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
