import React from "react";
import { NavLink } from "react-router-dom";
import "../SubCatDetails/subcatdetails.scss";
const SubCatDetails = ({ title, subtitle, subcatid, index, closeMenu }) => {
  return (
    <div className="description" key={index}>
      <NavLink
        to={{ pathname: `${title}/solution/${subcatid}` }}
        className="fs-28 ff-light subtitle"
        onClick={closeMenu}
      >
        {subtitle}
      </NavLink>
    </div>
  );
};

export default SubCatDetails;
