import axios from "axios";
import { checkAccessToken } from "./checkAccessToken";

export const BASE_API = "https://api.gnii.ai/api";

const axiosInstance = axios.create({
  baseURL: BASE_API,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      await checkAccessToken();
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        config.headers.Authorization = "Bearer " + accessToken;
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    console.log("axios request error >>>", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is 401 and it's not a retry request
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await checkAccessToken();
        const accessToken = localStorage.getItem("accessToken");

        if (accessToken) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;
          originalRequest.headers.Authorization = "Bearer " + accessToken;
        }

        return axiosInstance(originalRequest);
      } catch (error) {
        // Handle the error (e.g., redirect to login)
        console.log("Token refresh error >>>", error);
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
