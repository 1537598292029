import React, { useEffect, useState } from "react";
import "./developers.scss";
import Layout from "./layout";
import { ChevronRightIcon, Text } from "lucide-react";
import Markdown from "markdown-to-jsx";

import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Link, useLocation, useSearchParams } from "react-router-dom";

async function getFilePath(folder, name) {
  const path = `https://raw.githubusercontent.com/champ3oy/blackstar-docs/main/documentation/${folder}/${name}.md`;
  // console.log(path);
  let res = await fetch(path);
  const text = await res.text();
  // console.log(text);
  // Return the text content
  return text;
}

async function getFilePath2(name) {
  const path = `https://raw.githubusercontent.com/champ3oy/blackstar-docs/main/${name}.md`;
  // console.log(path);
  let res = await fetch(path);
  const text = await res.text();
  // console.log(text);
  // Return the text content
  return text;
}

function Developers() {
  const { pathname, search } = useLocation();

  const [doc, setDocs] = useState("");
  const [link, setlinks] = useState();
  let [searchParams, _] = useSearchParams();
  const path = pathname + search;

  let folder = searchParams.get("folder");
  let page = searchParams.get("page");

  useEffect(() => {
    (async () => {
      if (folder) {
        let text = await getFilePath(folder, page);

        setDocs(text);
        setlinks(extractHeadings(text));
      } else {
        let text = await getFilePath2(page ?? "readme");

        setDocs(text);
        setlinks(extractHeadings(text));
      }
    })();
  }, [page, folder]);

  useEffect(() => {
    document.getElementById("docpage").style.visibility = "hidden";
    document.getElementById("docpage").classList.remove("fade");
    setTimeout(() => {
      document.getElementById("docpage").style.visibility = "visible";
      document.getElementById("docpage").classList.add("fade");
    }, 100);
  }, [doc]);

  return (
    <Layout>
      <main
        id="docpage"
        className="fade2 relative py-6 lg:gap-10 lg:py-8 xl:grid xl:grid-cols-[1fr_300px] text-white "
      >
        <div className="mx-auto w-full min-w-0">
          <div className="mb-4 flex items-center space-x-1 text-sm text-[#9e9e9e] ">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
              Docs
            </div>
            <ChevronRightIcon className="h-4 w-4" />
            <div className="font-medium text-white">
              {link && link[0]?.text}
            </div>
          </div>
          <div className="markdown">
            <Markdown
              children={doc}
              options={{
                overrides: {
                  code: SyntaxHighlightedCode,
                },
              }}
            />
          </div>
        </div>
        <div className="w-full text-left  pl-10">
          <div className="fixed">
            <text className="text-[#9e9e9e] flex items-center text-sm mb-3">
              <Text size={19} className="mr-2" /> On this page
            </text>

            {link?.map((x) => {
              return (
                <a href={path + "#" + x?.slug} className="decoration-black">
                  <div className="mb-2 flex">
                    {/* <div className="w-3 flex justify-center items-center">
                    <Dot size={15} color="#be9a2b" />
                  </div> */}
                    <text className="text-base text-white">{x.text}</text>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default Developers;

function SyntaxHighlightedCode(props) {
  //   console.log(">>>", String(props.className).split("-")[1]);
  if (["lang-bash", "lang-json", "lang-javascript"].includes(props.className)) {
    return (
      <SyntaxHighlighter
        language={String(props.className).split("-")[1]}
        style={a11yDark}
      >
        {props.children}
      </SyntaxHighlighter>
    );
  } else {
    return <text className="code">{props.children}</text>;
  }
}

function extractHeadings(markdown) {
  const headingRegex = /^(#{1,6})\s+(.*)$/gm;
  const headings = [];
  let match;

  while ((match = headingRegex.exec(markdown)) !== null) {
    const level = match[1].length;
    const text = match[2];
    const slug = text.toLowerCase().replace(/\s+/g, "-");
    const slug1 = slug.toLowerCase().replace("(", "");
    const slug2 = slug1.toLowerCase().replace(")", "");
    headings.push({ level, text, slug: slug2 });
  }

  return headings;
}
