import React, { useEffect, useState } from "react";
import "../create_account.scss";
import { Link, useNavigate } from "react-router-dom";
import { getDeviceType } from "../../../../src/lib/utils";

function InitPage({ setPage }) {
  const navigate = useNavigate();
  const [fadeInAni, setFadeInAni] = useState("fade");
  const [selected, setselected] = useState("");

  useEffect(() => {
    setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
  }, []);

  const openModal = () => {
    const modal = document.getElementById("modal");
    modal.classList.remove("hidden");
    setTimeout(() => {
      modal.classList.add("visible");
    }, 10);
  };

  useEffect(() => {
    if (selected === "individual") {
      openModal();
    }
  }, [selected]);

  return (
    <div className="relative h-screen">
      <main
        className={`w-full ${fadeInAni} flex ${
          getDeviceType() === "mobile"
            ? "flex-col max-h-[90vh] overflow-y-hidden"
            : "flex-row min-h-max-[93vh] overflow-hidden"
        } justify-between `}
      >
        <div
          style={{
            borderRightWidth: getDeviceType() === "mobile" ? 0 : 0.5,
            borderRightColor: "#58595b7d",
            paddingTop: 150,
          }}
          className={`${
            getDeviceType() === "mobile" ? "w-full min-h-[90vh]" : "w-[47vw]"
          } text-white flex justify-start items-center flex-col `}
        >
          <h1 className="text-lg font-bold">Open an account</h1>
          <text>Sign up as an individual or institution</text>
          <div className="mt-5 gap-3 flex flex-col">
            <div
              onClick={() => {
                // setselected("individual");
                // document.getElementById("modal").style.top = 0;
                setPage(4);
              }}
              style={{
                borderColor: selected === "individual" ? "#be9a2b" : "white",
                borderWidth: 1,
              }}
              className="w-[350px] h-[70px] rounded-lg flex justify-start items-center px-3 relative cursor-pointer "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-user"
              >
                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
              <div className="ml-3 text-left">
                <h1 className="font-semibold">Individual</h1>
                <text className="text-xs">Continue as an Individual</text>
              </div>

              <div
                style={{
                  borderColor: selected === "individual" ? "#be9a2b" : "white",
                  borderWidth: 1,
                  background: selected === "individual" ? "#be9a2b" : "black",
                }}
                className="w-4 h-4 rounded-full absolute top-2 right-2"
              />
            </div>

            <div
              onClick={() => {
                setPage(1);
              }}
              className="w-[350px] h-[70px] border rounded-lg flex justify-start items-center px-3 relative cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-landmark"
              >
                <line x1="3" x2="21" y1="22" y2="22" />
                <line x1="6" x2="6" y1="18" y2="11" />
                <line x1="10" x2="10" y1="18" y2="11" />
                <line x1="14" x2="14" y1="18" y2="11" />
                <line x1="18" x2="18" y1="18" y2="11" />
                <polygon points="12 2 20 7 4 7" />
              </svg>
              <div className="ml-3 text-left">
                <h1 className="font-semibold">Institution</h1>
                <text className="text-xs">Continue as an Institution</text>
              </div>

              <div className="w-4 h-4 border rounded-full absolute top-2 right-2" />
            </div>

            <Link to={"/login"}>
              <h2
                className={fadeInAni}
                style={{
                  color: "#f4bd12",
                  textAlign: "center",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    color: "#c1c1c1",
                  }}
                >
                  Already have an account?
                </span>{" "}
                Login
              </h2>
            </Link>
          </div>
        </div>
        <div
          className={`${
            getDeviceType() === "mobile" ? "w-full h-[90vh]" : "w-[47vw]"
          } flex flex-col justify-between items-center max-h-full bg-black`}
        >
          <div className="py-10 flex flex-col gap-3 mt-10 -ml-16">
            <text className="text-white text-5xl  top-10">
              Join a New Generation <br />
              of Investors
            </text>
            <text className="text-white text-base  top-10">
              Sign Up to take control of your financial future
            </text>
            <div className="flex w-full justify-center items-center my-1 gap-2">
              <a href="https://play.google.com/store/apps/details?id=com.blackstargroup.ai&pli=1">
                <img
                  src="/playstore.png"
                  className="h-10 cursor-pointer"
                  style={{
                    borderRadius: "10px",
                    borderColor: "white",
                    borderWidth: 1,
                  }}
                />
              </a>
              <a href="https://testflight.apple.com/join/wyj4keKZ">
                <img
                  src="/appstore.png"
                  className="h-10 cursor-pointer"
                  style={{
                    borderRadius: "10px",
                    borderColor: "white",
                    borderWidth: 1,
                  }}
                />
              </a>
            </div>
            {/* <text className="text-zinc-400 text-sm">scan the QR code</text> */}
          </div>
          <img
            src="/bg2.png"
            className="-mr-5 w-[45%]"
            style={{
              marginLeft: -70,
            }}
          />
        </div>
      </main>

      {/* {selected === "individual" && getDeviceType() === "mobile" && ( */}
      <div
        id="modal"
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          zIndex: 9999,
          top: "100vh",
          left: 0,
          transition: "all .3s ease-out",
          display: getDeviceType() === "mobile" ? "flex" : "none",
        }}
        className={`flex flex-col justify-between items-center max-h-full bg-black`}
      >
        <div
          className="goback mt-3 absolute left-[20px]"
          onClick={() => {
            setselected("");
            document.getElementById("modal").style.top = "100vh";
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-move-left"
          >
            <path d="M6 8L2 12L6 16" />
            <path d="M2 12H22" />
          </svg>
          Go Back
        </div>
        <div className="w-full py-10 flex flex-col gap-3 mt-10 -ml-16 -ml-[10px]">
          <text className="text-white text-3xl  top-10">
            Get the Blackstar app
          </text>
          <text className="text-white text-lg  top-10">
            Your investments in your palm, <br />
            there's a better way.
          </text>
          <div className="flex w-full justify-center items-center my-1 gap-4 mt-2">
            <a
              href="https://play.google.com/store/apps/details?id=com.blackstargroup.ai&pli=1"
              className="border rounded-lg"
            >
              <img
                src="/playstore.png"
                className="h-10 cursor-pointer rounded-lg"
              />
            </a>
            <a
              href="https://testflight.apple.com/join/wyj4keKZ"
              className="border rounded-lg"
            >
              <img
                src="/appstore.png"
                className="h-10 cursor-pointer rounded-lg"
              />
            </a>
          </div>
          {/* <text className="text-zinc-400 text-sm">scan the QR code</text> */}
        </div>
        <img
          src="/bg2.png"
          className="-mr-5 w-[85%]"
          style={{
            marginLeft: -20,
          }}
        />
      </div>
      {/* )} */}
    </div>
  );
}

export default InitPage;
