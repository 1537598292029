"use client";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Link, useLocation } from "react-router-dom";

function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function DocsSidebarNav({ items }) {
  const { pathname, search } = useLocation();

  const path = pathname + search;

  return items.length ? (
    <div className="w-full">
      {items.map((item, index) => (
        <div
          key={index}
          className={cn("pb-4 flex flex-col justify-start items-start")}
        >
          <h4 className="mb-1 rounded-md py-1 text-base font-semibold text-white">
            {item.title}
          </h4>
          {item?.items?.length && (
            <DocsSidebarNavItems items={item.items} pathname={path} />
          )}
        </div>
      ))}
    </div>
  ) : null;
}

export function DocsSidebarNavItems({ items, pathname }) {
  return items?.length ? (
    <div className="grid grid-flow-row auto-rows-max text-base">
      {items.map((item, index) =>
        item.href && !item.disabled ? (
          <Link
            key={index}
            to={item.href}
            className={cn(
              "group flex w-full items-center rounded-md py-1 hover:underline hover:text-[#adfa1d] decoration-black",
              item.disabled && "cursor-not-allowed opacity-60",
              pathname === item.href
                ? "font-medium text-[#be9a2b]"
                : "text-[#9e9e9e]"
            )}
            target={item.external ? "_blank" : ""}
            rel={item.external ? "noreferrer" : ""}
          >
            {item.title}
            {item.label && (
              <span className="ml-2 rounded-md bg-[#adfa1d] px-1.5 py-0.5 text-xs leading-none text-[#000000] no-underline group-hover:no-underline decoration-black">
                {item.label}
              </span>
            )}
          </Link>
        ) : (
          <span
            key={index}
            className={cn(
              "flex w-full cursor-not-allowed items-center rounded-md p-2 text-muted-foreground hover:underline decoration-black",
              item.disabled && "cursor-not-allowed opacity-60"
            )}
          >
            {item.title}
            {item.label && (
              <span className="ml-2 rounded-md bg-muted px-1.5 py-0.5 text-xs leading-none text-muted-foreground no-underline group-hover:no-underline decoration-black">
                {item.label}
              </span>
            )}
          </span>
        )
      )}
    </div>
  ) : null;
}
