import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import axiosInstance, { BASE_API } from "../api/axios";

const SignaturePad = ({
  label = "",
  placeholder = "",
  value,
  onChange,
  required,
  kycid,
  identifier,
}) => {
  const [isSignaturePadVisible, setSignaturePadVisible] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const sigPadRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const clear = () => {
    sigPadRef.current.clear();
  };

  const trim = () => {
    const dataURL = sigPadRef.current.getTrimmedCanvas().toDataURL("image/png");
    setTrimmedDataURL(dataURL);
    setSignaturePadVisible(false); // Hide after trimming
    // onChange(dataURL);
  };

  const toggleSignaturePad = (e) => {
    e.preventDefault();
    setSignaturePadVisible(!isSignaturePadVisible);
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([uintArray], { type: mimeString });
  };

  const handleSignatureUpload = async () => {
    if (trimmedDataURL) {
      const blob = dataURLToBlob(trimmedDataURL);
      const formData = new FormData();
      formData.append("file", blob, "signature.png"); // Appending the blob as a file

      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[0].file", blob, "signature.png"); // Append signature as a file
        formData.append("files[0].identifier", identifier);

        const response = await axiosInstance.post(
          `${BASE_API}/kyc/${kycid}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          console.log("Signature uploaded successfully", response.data);
          onChange(response?.data["signature.png"]);
        }
      } catch (error) {
        console.error("Error uploading signature:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (trimmedDataURL) {
      (async () => {
        await handleSignatureUpload();
      })();
    } else {
      console.log("no signature");
    }
  }, [trimmedDataURL]);

  // Remove Uploaded Image
  const handleFileRemove = async () => {
    try {
      const response = await axiosInstance.delete(
        `${BASE_API}/kyc/${kycid}/document/${value}/delete`
      );
      if (response.status === 200) {
        console.log("File removed successfully");
        onChange(null);
      }
    } catch (error) {
      console.error("Error removing file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <label
        style={{
          marginTop: 10,
        }}
      >
        {label} <span style={{ color: "Red" }}>{required && "*"}</span>
      </label>
      {value ? (
        <div className="file-container">
          <div>
            <div className="file-name">File Uploaded</div>
            <div className="file-size">{value}</div>
          </div>
          <div className="file-remove">
            <button onClick={handleFileRemove}>Remove</button>
          </div>
        </div>
      ) : (
        <button
          style={styles.openButton}
          onClick={(e) => toggleSignaturePad(e)}
        >
          {loading
            ? "Loading..."
            : isSignaturePadVisible
            ? "Close Signature Pad"
            : "Open Signature Pad"}
        </button>
      )}

      {isSignaturePadVisible && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.sigContainer}>
              <SignatureCanvas
                canvasProps={{ style: styles.sigPad }}
                ref={sigPadRef}
              />
            </div>
            <div style={styles.buttonGroup}>
              <button style={styles.buttons} onClick={clear}>
                Clear
              </button>
              <button style={styles.buttons} onClick={trim}>
                Save
              </button>
              <button style={styles.buttons} onClick={toggleSignaturePad}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {trimmedDataURL && (
        <img style={styles.sigImage} alt="signature" src={trimmedDataURL} />
      )}
    </div>
  );
};

// Inline styles object
const styles = {
  container: {
    textAlign: "left",
    width: "49%",
  },
  openButton: {
    width: "100%",
    height: "45px",
    border: "1px solid #58595b7d",
    borderRadius: "7px",
    fontSize: "15px",
    fontFamily: "Outfit-Regular, sans-serif",
    fontStyle: "normal",
    textTransform: "capitalize",
    textDecoration: "none",
    color: "#fff",
    marginBottom: "5px",
    background: "transparent",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "#1d1d1d7d",
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "50vw",
    height: "30vw",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  sigContainer: {
    marginBottom: "20px",
    // height: 300,
  },
  buttons: {
    margin: "5px",
    padding: "10px 20px",
    backgroundColor: "#000000",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  sigPad: {
    border: "1px solid #ccc",
    width: "100%",
    height: 300,
  },
  sigImage: {
    marginTop: "20px",
    border: "1px solid #ccc",
    maxWidth: "100%",
    background: "#fff",
  },
};

export default SignaturePad;
