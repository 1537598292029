import React, { useEffect, useState } from "react";
import "../Partner/partner.scss";
import data from "../../../data";
import { useLocation } from "react-router-dom";

function Partner(props) {
  const params = useLocation();
  const [animationClass, setAnimationClass] = useState("fade");
  useEffect(() => {
    if (animationClass === "fade") {
      setAnimationClass("fade2");
    } else {
      setAnimationClass("fade");
    }
  }, [params.pathname]);
  return (
    <React.Fragment>
      <div className="section-partner">
        {data
          ?.filter((item) => item.title === props.props.title)
          .map((value) =>
            value.partnerclient?.map((values, id) => {
              return (
                <React.Fragment key={id}>
                  <div
                    className={`partner-content ${
                      animationClass === "fade" ? "fade2" : "fade"
                    }`}
                  >
                    <div className="partner-heading">
                      <h2>{values.title}</h2>
                    </div>
                    <div className="partner-desc">
                      <p>{values.description}</p>
                    </div>
                    <div className="partnerheading-wrapper">
                      {values.partnerdata?.map((head, i) => (
                        <div className="partnerdata-wrapper" key={i}>
                          <div className="partner-bottom">
                            {head.partnernumber?.map((num, index) => {
                              return (
                                <div className="number" key={index}>
                                  <span className="common-num">
                                    {num.common}
                                  </span>
                                  <span className="num">{num.number}</span>
                                  <span className="num-size">{num.size}</span>
                                </div>
                              );
                            })}
                            <div className="partnerdata-heading">
                              <h2>{head.title}</h2>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
      </div>
    </React.Fragment>
  );
}

export default Partner;
