import axios from "axios";
import { saveAs } from "file-saver";
const BASE_URL = "https://api.gnii.ai/api/public/funds";

const GetApi = (tag = "") => {
  return axios
    .get(BASE_URL + tag)
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch((error) => {
      return error.response;
    });
};

// const PostApi = (tag = '', reqBody) => {
//     return axios
//         .post(BASE_URL + tag, reqBody)
//         .then((data) => {
//             if (data.status === 200) {
//                 return data;
//             } else {
//                 return data;
//             }
//         })
//         .catch((error) => {
//             return error.response;
//         });
// };

// const DeleteApi = (tag = '') => {
//   return axios
//     .delete(BASE_URL + tag)
//     .then((data) => {
//       if (data.status === 200) {
//         return data;
//       } else {
//         return data;
//       }
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };
//
// const PutApi = (tag = '', reqBody, isHeader) => {
//   const headers = {
//     'Content-Type': 'image/jpeg',
//   };
//   return axios
//     .put(BASE_URL + tag, typeof reqBody !== null && reqBody, {
//       headers: isHeader ? headers : {},
//     })
//     .then((data) => {
//       if (data.status === 200) {
//         return data;
//       } else {
//         return data;
//       }
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

function getLastFridayDate() {
  // Get today's date
  const today = new Date();

  // Check if today is Monday (getDay() returns 1 for Monday)
  if (today.getDay() === 1) {
    // Calculate the date for last Friday
    // 5 is the difference between Monday (1) and Friday (5)
    const lastFriday = new Date(today);
    lastFriday.setDate(today.getDate() - 3); // 3 days before Monday

    // Format the date as YYYY-MM-DD
    const year = lastFriday.getFullYear();
    const month = String(lastFriday.getMonth() + 1).padStart(2, "0");
    const day = String(lastFriday.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // If today is not Monday, return null
  return "yesterday";
}

export const DOMAIN = "webapi.blackstargroup.ai";
// const DOMAIN = "localhost";

export const Api = {
  fundsReturn: (key) => GetApi(`/${key}/return`),
  positionExposureByAssetClassType: (key) =>
    GetApi(`/${key}/position-exposure/ASSET_CLASS_TYPE`),
  positionExposureByAssetTypeDetails: (key) =>
    GetApi(`/${key}/position-exposure/ASSET_TYPE_DETAIL`),
  performanceExcel: (key) => GetApi(`/${key}/performance/excel`),
  fundParticularPDF: (key) => GetApi(`/${key}/fundParticular`),
  fundsDetails: (key) => GetApi(`/${key}/detail`),
  dateWiseCumulativeReturn: (key) => GetApi(`/${key}/dateWiseCumulativeReturn`),
  performanceAttribute: (key) => GetApi(`/${key}/performance/attribute`),
  downloadFundParticular: (key) =>
    axios
      .get(`https://${DOMAIN}/fundParticular/${key}`)
      .then((res) => {
        // console.log(res.data);

        // Decode base64 string to binary data
        const binaryString = window.atob(res.data?.data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const blob = new Blob([bytes], { type: "application/pdf" });

        // Create an object URL and open it in a new tab
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");

        // Use saveAs to trigger the download with a specified file name
        saveAs(blob, res.data?.meta?.file_name || "download.pdf");

        return res.data;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }),
  performanceChart: (key) =>
    axios
      .post(`https://${DOMAIN}/cis/performance`, {
        key: key,
      })
      .then((res) => {
        return res.data?.data;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }),
  performanceChartYTD: (key) =>
    axios
      .post(`https://${DOMAIN}/cis/performanceYTD`, {
        key: key,
      })
      .then((res) => {
        return res.data?.data;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }),
  portfolioSummary: (key) =>
    axios
      .post(`https://${DOMAIN}/cis/summary`, { key: key })
      .then((res) => {
        return res.data?.data;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }),
  stockSummary: (key) =>
    axios
      .post(`https://${DOMAIN}/stock/summary`, { key: key })
      .then((res) => {
        return res.data?.data;
      })
      .catch((e) => {
        console.log(e);
        return false;
      }),
  getStockChart: (key) =>
    axios
      .post(`https://${DOMAIN}/stockChart`, { key })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getLocalSecurityChart: (key) =>
    axios
      .get(`https://${DOMAIN}/perfDate/${formatDateRange()}/${key}`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getCalculatedRisks: (key) =>
    axios
      .get(`https://${DOMAIN}/calculatedRisk/${key}`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getCISData: (key, nav) =>
    axios
      .post(`https://${DOMAIN}/cis/newholdings`, {
        ticker: key,
        nav,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getCISData2: (key, nav) =>
    axios
      .post(`https://${DOMAIN}/cis/holdings`, {
        ticker: key,
        nav,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getMetrics: (key, nav) =>
    axios
      .post(`https://${DOMAIN}/cis/metrics`, {
        ticker: key,
        nav,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getLivePrices: (key, nav) =>
    axios
      .get(`https://${DOMAIN}/livePrice`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
  getSnapshot: () =>
    axios
      .get(`https://${DOMAIN}/snapshot`)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        console.log(">>>>>> ", e);
        return false;
      }),
};

function formatDateRange() {
  const today = new Date();
  const lastMonth = new Date(today);

  // Set the date to a month ago
  lastMonth.setMonth(today.getMonth() - 1);

  // Adjust the day if the previous month has fewer days
  if (today.getDate() !== lastMonth.getDate()) {
    lastMonth.setDate(0); // setDate(0) sets it to the last day of the previous month
  }

  // Helper function to format the date as DD-MM-YYYY
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // Format the two dates
  const todayFormatted = formatDate(today);
  const lastMonthFormatted = formatDate(lastMonth);

  // Return the string in the format "DD-MM-YYYY/DD-MM-YYYY"
  return `${lastMonthFormatted}/${todayFormatted}`;
}
