import React, { useState, useEffect } from "react";
import "./advisory.scss";
import Overview from "../../Tabination/Overview/Overview";
import Partner from "../../Tabination/Partner/Partner";
import Products from "../../Tabination/Products/Products";
import Solution from "../../Tabination/Solution/Solution";
import Stats from "../../Tabination/Stats/Stats";
import { useParams } from "react-router-dom";
import Resources from "../../Tabination/Resources/Resources";

function Advisory(props) {
  const [tab, setTab] = useState("overview");
  const params = useParams();
  useEffect(() => {
    if (params.type === "solution") {
      setTab("solution");
    }
    if (params.type === "overview") {
      setTab("overview");
    }
  }, [params.type, params.id]);
  return (
    <React.Fragment>
      <div className="mt-4 res-overview">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={tab === "overview" ? "nav-link active" : "nav-link"}
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => setTab("overview")}
            >
              Overview
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className={tab === "solution" ? "nav-link active" : "nav-link"}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => setTab("solution")}
            >
              Solutions
            </button>
          </li>
          {/*<li className="nav-item" role="presentation">*/}
          {/*  <button*/}
          {/*    className={tab === "product" ? "nav-link active" : "nav-link"}*/}
          {/*    id="pills-contact-tab"*/}
          {/*    data-bs-toggle="pill"*/}
          {/*    data-bs-target="#pills-contact"*/}
          {/*    type="button"*/}
          {/*    role="tab"*/}
          {/*    aria-controls="pills-contact"*/}
          {/*    aria-selected="false"*/}
          {/*    onClick={() => setTab("product")}*/}
          {/*  >*/}
          {/*    Products*/}
          {/*  </button>*/}
          {/*</li>*/}
          <li className="nav-item" role="presentation">
            <button
              className={tab === "stats" ? "nav-link active" : "nav-link"}
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-stats"
              type="button"
              role="tab"
              aria-controls="pills-stats"
              aria-selected="false"
              onClick={() => setTab("stats")}
            >
              Stats
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={tab === "partner" ? "nav-link active" : "nav-link"}
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-partners"
              type="button"
              role="tab"
              aria-controls="pills-partners"
              aria-selected="false"
              onClick={() => {
                setTab("partner");
              }}
            >
              Partners / Clients
            </button>
          </li>
          {/*<li className="nav-item" role="presentation">*/}
          {/*  <button*/}
          {/*    className={tab === "resources" ? "nav-link active" : "nav-link"}*/}
          {/*    id="pills-contact-tab"*/}
          {/*    data-bs-toggle="pill"*/}
          {/*    data-bs-target="#pills-resources"*/}
          {/*    type="button"*/}
          {/*    role="tab"*/}
          {/*    aria-controls="pills-resources"*/}
          {/*    aria-selected="false"*/}
          {/*    onClick={() => {*/}
          {/*      setTab("resources");*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Resources*/}
          {/*  </button>*/}
          {/*</li>*/}
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className={`tab-pane fade ${
              tab === "overview" && "fade-in active"
            }`}
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <Overview props={props} setTab={setTab} />
          </div>
          <div
            className={`tab-pane fade ${
              tab === "solution" && "fade-in active"
            }`}
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <Solution props={props} />
          </div>
          {/*<div*/}
          {/*  className={`tab-pane fade ${tab === "product" && "fade-in active"}`}*/}
          {/*  id="pills-contact"*/}
          {/*  role="tabpanel"*/}
          {/*  aria-labelledby="pills-contact-tab"*/}
          {/*>*/}
          {/*  <Products props={props} />*/}
          {/*</div>*/}
          <div
            className={`tab-pane fade ${tab === "stats" && "fade-in active"}`}
            id="pills-stats"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <Stats props={props} />
          </div>
          <div
            className={`tab-pane fade ${tab === "partner" && "fade-in active"}`}
            id="pills-partners"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <Partner props={props} />
          </div>
          {/*<div*/}
          {/*  className={`tab-pane fade ${*/}
          {/*    tab === "resources" && "fade-in active"*/}
          {/*  }`}*/}
          {/*  id="pills-resources"*/}
          {/*  role="tabpanel"*/}
          {/*  aria-labelledby="pills-contact-tab"*/}
          {/*>*/}
          {/*  <Resources props={props} />*/}
          {/*</div>*/}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Advisory;
