import React, { useState} from "react";
import "../OurMethodology/our_methodology.scss";
import ChevronLeft from "../../Assets/left-chevron.png";
import {Link, useNavigate} from "react-router-dom";

const OUR_METHODOLOGY = [
    {
        name: "Prioritize Liquidity",
        description: "Seek to minimize risk with this fund. The fund consists of very liquid short-term fixed income securities and inflationary-protected securities.",
        tag: "High liquidity fund for short-term needs",
    },
    {
        name: "Aggressive  Growth",
        description: "An investment strategy that invests in a blend of stocks and bonds designed to closely match the performance of a broad market index.",
        tag: "Tech Focused stocks with high growth potential"
    },
    {
        name: "Regular Income",
        description: "This fund is designed to invest in stocks that pay significant yields for shareholders and are generally held in tax-advantaged accounts.",
        tag: "Dividend Focused stocks & bonds with yield potential"
    },
    {
        name: "Balanced Growth",
        description: "The Fund invests in a balanced mix of fixed income securities such as bonds and money market instruments.",
        tag: "A hybrid fund geared towards stable, less volatile growth"
    },
    {
        name: "Utilize Idle Cash",
        description: "The fund consists of short-term Ghanaian securities that are highly liquid and easily convertible.",
        tag: "High liquidity with a focus on Ghanaian investment products"
    },
];

function OurMethodology() {
    const navigate = useNavigate();
    const [fadeInAni, setFadeInAni] = useState("fade");
    const [currentAccordion, setCurrentAccordion] = useState(0);

    const handleAccordion = (index) => {
        if (index !== currentAccordion) {
            setCurrentAccordion(index);
            setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
        } else {
            setFadeInAni(fadeInAni === "fade" ? "fade2" : "fade");
            setCurrentAccordion(6);
        }
    }

    return (<React.Fragment>
        <div className="our_methodology text-white text-start mt-4">
            <h1>Our Methodology</h1>
            <p className="m-0 our_methodology_desc fs-22 ff-regular">We explore and allocate your assets by your stated
                goals</p>
            <div className="our_methodology_head mt-3">
                <div className="d-flex" onClick={() => {
                    navigate("/investment_goal")
                }}>
                    <img className="mt-1 align-self-center me-2 rot" src={ChevronLeft} alt="chevron-left"/>
                    <p className="m-0 align-self-center">Back</p>
                </div>
            </div>
            <div className="accordion mt-4" id="accordionExample">
                {OUR_METHODOLOGY?.map((data, index) => {
                    return (
                        <div className={`accordion-item ${fadeInAni}`} key={index}>
                            <h2 className="accordion-header" id="headingOne" onClick={() => handleAccordion(index)}>
                                <button className={
                                    index === currentAccordion
                                        ? "accordion-button"
                                        : "accordion-button collapsed"
                                } type="button" data-bs-toggle="collapse"
                                        data-bs-target={`"#collapse${index}"`} aria-expanded="true"
                                        aria-controls={`"collapse${index}"`}>
                                    <div className="resacc-div"><span className="acc-title">{data.name}</span><span
                                        className="acc-tag">{data.tag}</span>
                                    </div>
                                </button>
                            </h2>
                            <div id={`"collapse${index}"`}
                                 className={`accordion-collapse collapse ${index === currentAccordion ? "show" : ""}`}
                                 aria-labelledby={`"heading${index}"`}
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p>
                                        {data.description} <Link
                                        to={`/ifunds?menu=${index + 1}`}
                                        className="more text-white fs-16 ff-regular"
                                    >
                                        More
                                    </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </React.Fragment>);
}

export default OurMethodology;
