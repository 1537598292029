"use client";

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import { useEffect, useState } from "react";
import { getDeviceType } from "../../../../src/lib/utils";

export function BarChartComp({ data }) {
  let [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(data);
  }, [data]);

  const chartConfig = {
    value: {
      label: "Percentage",
      color: "white",
    },
  };

  return (
    <div className="w-full h-[200px] -ml-1 ">
      <ChartContainer className=" h-full w-full " config={chartConfig}>
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: getDeviceType() === "mobile" ? -120 : -20,
          }}
        >
          <CartesianGrid
            vertical={true}
            horizontal={false}
            strokeWidth={1}
            stroke="#28282B"
          />
          <XAxis
            tickLine={false}
            type="number"
            dataKey="value"
            ticks={[0, 25, 50, 75, 100]}
          />
          <YAxis
            dataKey="label"
            type="category"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) =>
              getDeviceType() === "mobile"
                ? String(value).substring(0, 4) + "..."
                : value
            }
            width={180}
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent className="bg-black p-2" suffix="%" />
            }
            custom={true}
          />
          <Bar dataKey="value" fill={"#ffffff90"} radius={5} barSize={50} />
        </BarChart>
      </ChartContainer>
    </div>
  );
}
