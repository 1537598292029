import React from "react";
import "../People/people.scss";

function People() {
  const PeopleData = [
    {
      title: "People",
      directors: [
        {
          title: "Management",
          category: [
            {
              subId: "eric1",
              subtitle: "Eric Appiah",
              tag: "ADVISORY",
              description: [
                {
                  bullet1:
                    "Eric is responsible for running and managing all mandates and transactions in the BSA business globally. He was previously with CairnCapital in London, UK and served as a Vice-President in Bear Stearns’ London office . After which he moved to IC Securities (Ghana) Limited to act as Executive Director in the Investment Banking unit and was also the acting of IC Asset Managers.",
                  bullet2:
                    "Eric holds both a BSc. in Mathematics & Statistics and an MSc. in Corporate Risk & Security Management from the University of Southampton.",
                  bullet3:
                    "He is registered as an Approved Person by the United Kingdom’s Financial Services Authority, is certified as a Financial Risk Manager by the Global Association of Risk Professionals.",
                },
              ],
            },
            {
              subId: "nanaagyei1",
              subtitle: "Nana Agyei Opoku-Agyemang",
              tag: "MARKETS",
              description: [
                {
                  bullet1:
                    "Nana is primarily responsible for overseeing the implementation of corporate strategy, risk management and execution of mandates/assignment.",
                  bullet2:
                    "He has over 10 years of experience in Investment Banking and Research with CALBrokers Ltd. Nana Agyei has an MBA, Finance from Coventry University and BSc. Mathematics from Kwame Nkrumah University of Science & Technology.",
                  bullet3:
                    "He is an Authorized Dealing Officer of the Ghana Stock Exchange and also has affiliation with the Ghana Stockbrokers Association and Ghana Securities Industry Association.",
                },
              ],
            },
            {
              subId: "adjeiboateng1",
              subtitle: "Adjei Boateng",
              tag: "ASSET MANAGEMENT",
              description: [
                {
                  bullet1:
                    "Adjei Boateng heads the Asset Management Division of the Group. Before joining the Black Star team in Ghana, he served as a Portfolio Manager, Research & Performance Analyst with over 8 years of industry experience in the United Kingdom. He worked with Barclays Wealth and Investment Management and Liontrust Asset Management before coming to Ghana.",
                  bullet2:
                    "Adjei Boateng graduated with a Bachelor of Science (BSc) in Economics, Finance and Banking. He also has a MSc, Finance and Investment from the University of Bristol, UK. He holds the Investment Management Certificate (IMC) Unit 1 and 2 and is a CFA Charter Holder.",
                },
              ],
            },
          ],
        },
      ],
      //
      // management: [
      //   {
      //     title: "Management",
      //     category: [
      //       {
      //         subId: "eric2",
      //         subtitle: "Eric Appiah",
      //         tag: "ADVISORY",
      //         description: [
      //           {
      //             bullet1:
      //               "Eric is responsible for running and managing all mandates and transactions in the BSA business globally. He was previously with CairnCapital in London, UK and served as a Vice-President in Bear Stearns’ London office . After which he moved to IC Securities (Ghana) Limited to act as Executive Director in the Investment Banking unit and was also CEO of IC Asset Managers.",
      //             bullet2:
      //               "Eric holds both a BSc. in Mathematics & Statistics and an MSc. in Corporate Risk & Security Management from the University of Southampton.",
      //             bullet3:
      //               "He is registered as an Approved Person by the United Kingdom’s Financial Services Authority, is certified as a Financial Risk Manager by the Global Association of Risk Professionals.",
      //           },
      //         ],
      //       },
      //       {
      //         subId: "nanaagyei2",
      //         subtitle: "Nana Agyei Opoku-Agyemang",
      //         tag: "MARKETS",
      //         description: [
      //           {
      //             bullet1:
      //               "Nana is primarily responsible for overseeing the implementation of corporate strategy, risk management and execution of mandates/assignment.",
      //             bullet2:
      //               "He has over 10 years of experience in Investment Banking and Research with CALBrokers Ltd. Nana Agyei has an MBA, Finance from Coventry University and BSc. Mathematics from Kwame Nkrumah University of Science & Technology.",
      //             bullet3:
      //               "He is an Authorized Dealing Officer of the Ghana Stock Exchange and also has affiliation with the Ghana Stockbrokers Association and Ghana Securities Industry Association.",
      //           },
      //         ],
      //       },
      //       {
      //         subId: "adjeiboateng2",
      //         subtitle: "Adjei Boateng",
      //         tag: "ASSET MANAGEMENT",
      //         description: [
      //           {
      //             bullet1:
      //               "Adjei Boateng heads the Asset Management Division of the Group. Before joining the Black Star team in Ghana, he served as a Portfolio Manager, Research & Performance Analyst with over 8 years of industry experience in the United Kingdom. He worked with Barclays Wealth and Investment Management and Liontrust Asset Management before coming to Ghana.",
      //             bullet2:
      //               "Adjei Boateng graduated with a Bachelor of Science (BSc) in Economics, Finance and Banking. He also has a MSc, Finance and Investment from the University of Bristol, UK. He holds the Investment Management Certificate (IMC) Unit 1 and 2 and is a CFA Charter Holder.",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
    },
  ];
  return (
    <React.Fragment>
      <div className="section-people">
        {PeopleData?.map((values, index) => {
          return (
            <div className="people-content" key={index}>
              <div className="people-heading">
                <span className="horizontal-line d-block"></span>
                <h2 className="people-title">{values.title}</h2>
              </div>
              {values?.directors?.map((head, key) => {
                return (
                  <div className="header-wrapper" index={key}>
                    <h4>{head.title}</h4>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      {head?.category?.map((val, i) => {
                        return (
                          <div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="flush-headingTwo">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-${val.subId}`}
                                aria-expanded="false"
                                aria-controls={`flush-${val.subId}`}
                              >
                                <div className="resacc-people">
                                  <span className="acc-title">
                                    {val.subtitle}
                                  </span>
                                  <span className="acc-tag">{val.tag}</span>
                                </div>
                              </button>
                            </h2>
                            <div
                              id={`flush-${val.subId}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="flush-headingTwo"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body">
                                {val?.description.map((bullets) => (
                                  <>
                                    <p>{bullets.bullet1}</p>
                                    <p>{bullets.bullet2}</p>
                                    <p>{bullets.bullet3}</p>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              <div className="section-management">
                {values?.management?.map((head, key) => {
                  return (
                    <div className="header-wrapper" index={key}>
                      <h4>{head.title}</h4>
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample_management"
                      >
                        {head?.category?.map((val1, i) => {
                          return (
                            <div className="accordion-item" key={i}>
                              <h2
                                className="accordion-header"
                                id="flush-headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#flush-${val1.subId}`}
                                  aria-expanded="false"
                                  aria-controls={`flush-${val1.subId}`}
                                >
                                  <div className="resacc-people">
                                    <span className="acc-title">
                                      {val1.subtitle}
                                    </span>
                                    <span className="acc-tag">{val1.tag}</span>
                                  </div>
                                </button>
                              </h2>
                              <div
                                id={`flush-${val1.subId}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingThree"
                                data-bs-parent="#accordionFlushExample_management"
                              >
                                <div className="accordion-body">
                                  {val1?.description.map((bullets) => (
                                    <>
                                      <p>{bullets.bullet1}</p>
                                      <p>{bullets.bullet2}</p>
                                      <p>{bullets.bullet3}</p>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default People;
