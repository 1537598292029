// src/components/TradingHours.js
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { isUSHoliday, isWeekend } from "../../../../src/lib/utils";

export const getMarketStatus = (isIndex) => {
  const currentTime = moment.tz("America/New_York");

  // Define market times in minutes since midnight
  const preMarketStart = isIndex ? 4 * 60 : 0; // 4:00 AM
  const marketStart = isIndex ? 9.5 * 60 : 8.5 * 60; // 9:30 AM
  const marketEnd = isIndex ? 16 * 60 : 16.5 * 60; // 4:00 PM
  const postMarketEnd = isIndex ? 20 * 60 : 0; // 8:00 PM

  const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();

  let stat = "";
  let status = "";
  let remainingTime = "";

  const now = new Date();

  // Check if today is a weekend or US holiday
  if (isWeekend(now) || isUSHoliday(now)) {
    return {
      stat: "Market Closed",
      status: "Market is closed.",
      remainingTime: 0,
    };
  }

  if (currentMinutes >= preMarketStart && currentMinutes < marketStart) {
    stat = "Pre-Market";
    status = "Market is open for pre-market trading.";
    const minutesUntilOpen = marketStart - currentMinutes;
    const hours = Math.floor(minutesUntilOpen / 60);
    const minutes = minutesUntilOpen % 60;
    remainingTime = `Market opens in ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
  } else if (currentMinutes >= marketStart && currentMinutes < marketEnd) {
    stat = "Market Open";
    status = "Market is open for trading.";
    const minutesUntilClose = marketEnd - currentMinutes;
    const hours = Math.floor(minutesUntilClose / 60);
    const minutes = minutesUntilClose % 60;
    remainingTime = `Market closes in ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
  } else if (currentMinutes >= marketEnd && currentMinutes < postMarketEnd) {
    stat = "Post-Market";
    status = "Market is open for post-market trading.";
    const minutesUntilClose = postMarketEnd - currentMinutes;
    const hours = Math.floor(minutesUntilClose / 60);
    const minutes = minutesUntilClose % 60;
    remainingTime = `Post-market closes in ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
  } else {
    stat = "Market Closed";
    status = "Market is closed.";
    if (currentMinutes < preMarketStart) {
      const minutesUntilPreMarket = preMarketStart - currentMinutes;
      const hours = Math.floor(minutesUntilPreMarket / 60);
      const minutes = minutesUntilPreMarket % 60;
      remainingTime = `Pre-market opens in ${hours} hour${
        hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
    } else {
      const minutesUntilPreMarket = 1440 - currentMinutes + preMarketStart; // Minutes until midnight plus minutes to pre-market
      const hours = Math.floor(minutesUntilPreMarket / 60);
      const minutes = minutesUntilPreMarket % 60;
      remainingTime = `Pre-market opens in ${hours} hour${
        hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
    }
  }

  return {
    stat,
    status,
    remainingTime,
  };
};

const TradingHours = (isIndex) => {
  const [currentTime, setCurrentTime] = useState(moment.tz("America/New_York"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.tz("America/New_York"));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  // Trading hours in New York time
  const preMarketStart = isIndex ? 4 * 60 : 0; // 4:00 AM
  const marketStart = isIndex ? 9.5 * 60 : 8 * 60; // 9:30 AM
  const marketEnd = isIndex ? 16 * 60 : 16.5 * 60; // 4:00 PM
  const postMarketEnd = isIndex ? 20 * 60 : 0; // 8:00 PM

  const getTimePosition = (timeInMinutes) => (timeInMinutes / 1440) * 100; // 1440 minutes in a day

  const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();
  const currentPosition = getTimePosition(currentMinutes);

  // Generate ticks for each hour
  const ticks = Array.from({ length: 60 }, (v, i) => i + 1);

  let data = getMarketStatus(isIndex);

  const now = new Date();

  // Check if today is a weekend or US holiday
  if (isWeekend(now) || isUSHoliday(now)) {
    return <div className="w-0 h-0 hidden" />;
  }

  return (
    <div className="flex flex-col pb-3 fade">
      <text className="text-lg font-bold text-white">
        {data?.stat}{" "}
        <span className="text-base font-normal text-zinc-500 mt-2">
          New York (UTC-4)
        </span>
      </text>
      <text className="text-sm text-zinc-500 mt-2">
        {data?.status} {data?.remainingTime}
      </text>
      <div className="h-2" />
      <div className="relative w-full">
        <div
          className="absolute -top-2 h-fit border-l-2 border-black z-10 flex justify-center items-center flex-col"
          style={{ left: `${currentPosition - 1}%` }}
        >
          <div className="h-5 w-1  rounded-full flex justify-center items-center text-white">
            <svg
              width="6"
              height="22"
              viewBox="0 0 6 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              left="78.43747474747475"
              class="sc-2b9839ae-1 dyjrNx absolute -top-[2px] [&amp;_path]:light:fill-black-original [&amp;_rect]:light:fill-black-original"
            >
              <path
                d="M0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3V4C6 5.65685 4.65685 7 3 7C1.34315 7 0 5.65685 0 4V3Z"
                fill="currentColor"
              ></path>
              <rect
                x="2"
                y="7"
                width="2"
                height="15"
                rx="1"
                fill="currentColor"
              ></rect>
            </svg>
          </div>
          <div className="relative -top-2 text-[10px] text-white mt-2.5">
            {/* {currentTime.format("HH:mm")} */}
          </div>
        </div>
        <div className="absolute top-0 left-0 h-full w-full  text-white justify-between items-center flex flex-row">
          {ticks.map((tick, index) => (
            <div
              key={index}
              className=" top-0 h-full w-[2px] bg-zinc-800 rounded-full"
            />
          ))}
        </div>
        <div className="relative w-full h-1.5  rounded-lg overflow-hidden">
          <div
            className="absolute top-0 h-full bg-yellow-300 rounded-full"
            style={{
              left: `${getTimePosition(preMarketStart)}%`,
              width: `${getTimePosition(marketStart - preMarketStart)}%`,
            }}
          />
          <div
            className="absolute top-0 h-full bg-green-300  rounded-full"
            style={{
              left: `${getTimePosition(marketStart)}%`,
              width: `${getTimePosition(marketEnd - marketStart)}%`,
            }}
          />
          <div
            className="absolute top-0 h-full bg-red-500  rounded-full"
            style={{
              left: `${getTimePosition(marketEnd)}%`,
              width: `${getTimePosition(postMarketEnd - marketEnd)}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TradingHours;

const getMarketStatus2 = (isIndex) => {
  const currentTime = moment.tz("Africa/Accra");

  // Define market times in minutes since midnight
  const preMarketStart = 9.5 * 60; // 9:00 AM
  const marketStart = 10 * 60; // 10:30 AM
  const marketEnd = 15 * 60; // 3:00 PM

  const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();

  let stat = "";
  let status = "";
  let remainingTime = "";

  if (currentMinutes >= preMarketStart && currentMinutes < marketStart) {
    stat = "Pre-Market";
    status = "Market is open for pre-market trading.";
    const minutesUntilOpen = marketStart - currentMinutes;
    const hours = Math.floor(minutesUntilOpen / 60);
    const minutes = minutesUntilOpen % 60;
    remainingTime = `Market opens in ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
  } else if (currentMinutes >= marketStart && currentMinutes < marketEnd) {
    stat = "Market Open";
    status = "Market is open for trading.";
    const minutesUntilClose = marketEnd - currentMinutes;
    const hours = Math.floor(minutesUntilClose / 60);
    const minutes = minutesUntilClose % 60;
    remainingTime = `Market closes in ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
  } else {
    stat = "Market Closed";
    status = "Market is closed.";
    if (currentMinutes < preMarketStart) {
      const minutesUntilPreMarket = preMarketStart - currentMinutes;
      const hours = Math.floor(minutesUntilPreMarket / 60);
      const minutes = minutesUntilPreMarket % 60;
      remainingTime = `Pre-market opens in ${hours} hour${
        hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
    } else {
      const minutesUntilPreMarket = 1440 - currentMinutes + preMarketStart; // Minutes until midnight plus minutes to pre-market
      const hours = Math.floor(minutesUntilPreMarket / 60);
      const minutes = minutesUntilPreMarket % 60;
      remainingTime = `Pre-market opens in ${hours} hour${
        hours !== 1 ? "s" : ""
      } ${minutes} minute${minutes !== 1 ? "s" : ""}.`;
    }
  }

  return {
    stat,
    status,
    remainingTime,
  };
};

export const TradingHours2 = (isIndex) => {
  const [currentTime, setCurrentTime] = useState(moment.tz("Africa/Accra"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.tz("Africa/Accra"));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  // Trading hours in New York time
  const preMarketStart = 9.5 * 60; // 9:00 AM
  const marketStart = 10 * 60; // 10:30 AM
  const marketEnd = 15 * 60; // 3:00 PM

  const getTimePosition = (timeInMinutes) => (timeInMinutes / 1440) * 100; // 1440 minutes in a day

  const currentMinutes = currentTime.hours() * 60 + currentTime.minutes();
  const currentPosition = getTimePosition(currentMinutes);

  // Generate ticks for each hour
  const ticks = Array.from({ length: 60 }, (v, i) => i + 1);

  let data = getMarketStatus2(isIndex);

  return (
    <div className="flex flex-col pb-3 fade">
      <text className="text-lg font-bold text-white">
        {data?.stat}{" "}
        <span className="text-base font-normal text-zinc-500 mt-2">
          Accra (UTC)
        </span>
      </text>
      <text className="text-sm text-zinc-500 mt-2">
        {data?.status} {data?.remainingTime}
      </text>
      <div className="h-2" />
      <div className="relative w-full">
        <div
          className="absolute -top-2 h-fit border-l-2 border-black z-10 flex justify-center items-center flex-col"
          style={{ left: `${currentPosition - 1}%` }}
        >
          <div className="h-5 w-1  rounded-full flex justify-center items-center text-white">
            <svg
              width="6"
              height="22"
              viewBox="0 0 6 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              left="78.43747474747475"
              class="sc-2b9839ae-1 dyjrNx absolute -top-[2px] [&amp;_path]:light:fill-black-original [&amp;_rect]:light:fill-black-original"
            >
              <path
                d="M0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3V4C6 5.65685 4.65685 7 3 7C1.34315 7 0 5.65685 0 4V3Z"
                fill="currentColor"
              ></path>
              <rect
                x="2"
                y="7"
                width="2"
                height="15"
                rx="1"
                fill="currentColor"
              ></rect>
            </svg>
          </div>
          <div className="relative -top-2 text-[10px] text-white mt-2.5">
            {/* {currentTime.format("HH:mm")} */}
          </div>
        </div>
        <div className="absolute top-0 left-0 h-full w-full  text-white justify-between items-center flex flex-row">
          {ticks.map((tick, index) => (
            <div
              key={index}
              className=" top-0 h-full w-[2px] bg-zinc-800 rounded-full"
            />
          ))}
        </div>
        <div className="relative w-full h-1.5  rounded-lg overflow-hidden">
          <div
            className="absolute top-0 h-full bg-yellow-300 rounded-full"
            style={{
              left: `${getTimePosition(preMarketStart)}%`,
              width: `${getTimePosition(marketStart - preMarketStart)}%`,
            }}
          />
          <div
            className="absolute top-0 h-full bg-green-300  rounded-full"
            style={{
              left: `${getTimePosition(marketStart)}%`,
              width: `${getTimePosition(marketEnd - marketStart)}%`,
            }}
          />
          {/* <div
            className="absolute top-0 h-full bg-red-500  rounded-full"
            style={{
              left: `${getTimePosition(marketEnd)}%`,
              width: `${getTimePosition(postMarketEnd - marketEnd)}%`,
            }}
          /> */}
        </div>
      </div>
    </div>
  );
};
